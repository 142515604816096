


import * as XLSX from "xlsx";
import { Grid, Stack, Pagination, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstanceAuth } from "apiInstances";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import { FaDownload } from "react-icons/fa6";
const WithdrawRecords = () => {
  let navigate = useNavigate();
  const [requestData, setRequestData] = useState([]);
  const [oldRequestData, setOldRequestData] = useState([]);

  const { encryptData, decryptData } = useEncryption();
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);

  const [page, setPage] = useState(1);
  console.log("🚀 ~ WithdrawRecords ~ page:", page)

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  const getWithdrawRecords = async () => {
    try {
      const encryptedData = encryptData();
     await axiosInstanceAuth
        .get(`/withdrawRecords`, {
          data: encryptedData,
        })
        .then((res) => {
          console.log("🚀 ~ .then ~ res:", res)
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ .then ~ responseData:", responseData)

          
          if (responseData.status) {
            setPage(Math.ceil(res.data.count / 10));

            const temp = [];
            responseData.data.map((data, index) => {
              temp.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                name: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data.username}`}
                  </MDTypography>
                ),
                email: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.email}
                  </MDTypography>
                ),
                Address: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data?.withdrawHistory?.address ? data?.withdrawHistory?.address : 0}
                  </MDTypography>
                ),
                ticketRewards: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data?.withdrawHistory?.ticketRewards
                      ? data?.withdrawHistory?.ticketRewards
                      : data?.withdrawHistory?.nodeRewards}
                  </MDTypography>
                ),

                withdrawal_date: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {new Date(data.withdrawHistory.createdAt.toString()).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </MDTypography>
                ),
              });
            });

            setRequestData(temp);
            setOldRequestData(temp);
            setLoading(false);
          }
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.log("🚀 ~ getUser ~ error:", error);
    }
  };

  const handleInput = (e) => {
    if (e.target.value) {
      const searchData = oldRequestData.filter((i) => {
        return (
          i?.email?.props?.children
            ?.toLowerCase()
            .trim()
            .includes(e.target.value.toLowerCase().trim()) ||
          i?.name?.props?.children
            ?.toLowerCase()
            .trim()
            .includes(e.target.value.toLowerCase().trim()) ||
          i?.ticketRewards?.props?.children
            ?.toString()
            ?.toLowerCase()
            .trim()
            .includes(e.target.value.toLowerCase().trim())
        );
      });

      setRequestData(searchData);
    } else {
      setRequestData(oldRequestData);
    }
  };

  useEffect(() => {
    checkToken();
    getWithdrawRecords();
  }, []);

  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Address", accessor: "Address", align: "left" },
    { Header: "Ticket Rewards", accessor: "ticketRewards", align: "left" },
    { Header: "Withdrawal Date", accessor: "withdrawal_date", align: "left" },
  ];

  const onPaginationTable = () => {
    getWithdrawRecords();
  };
  const downloadReport = async() => {
    setLoading1(true);
    try {
      const encryptedData = encryptData();
     await axiosInstanceAuth
        .get(`/withdrawRecords`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ file: index.js:159 ~ .then ~ responseData:", responseData);
          const formattedData = responseData.data.map((data) => ({
            name: data.username,
            email: data.email,
            Address: data.withdrawHistory.address,
            ticketRewards: data.withdrawHistory.ticketRewards
              ? data.withdrawHistory.ticketRewards
              : data.withdrawHistory.nodeRewards,
            withdrawal_date:  new Date(data.withdrawHistory.createdAt.toString()).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "short",
                day: "numeric",
              }
            )
          }));
          const workSheet = XLSX.utils.json_to_sheet(formattedData);
          const workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "users");
          //Buffer
          let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
          //Binary string
          XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
          //Download
          XLSX.writeFile(workBook, "withdrawRewards.xlsx");
          setLoading1(false);
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {}
  };
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              {/* {loading1 ? (
                <div className="dot-spin-container">
                  <div className="dot-spin1"></div>
                  <div className="dot-spin-text">Downloading file </div>
                </div>
              ) : ( */}
              <div className="dot-spin"></div>
              {/* )} */}
            </div>
          </div>
        ) : (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="coinTitleColor"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <div className="box-user ">
                      <MDTypography variant="h6" color="white">
                        Nodes
                      </MDTypography>
                      {loading1 ? (
                        <>
                          <div className="flex justify-center items-center gap-2">
                            <div className="dot-spin1"></div>
                            <MDButton
                              onClick={downloadReport}
                              variant="contained"
                              color="info"
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              Downloading ..
                            </MDButton>
                          </div>
                        </>
                      ) : (
                        <>

                        <MDButton
                          onClick={downloadReport}
                          variant="contained"
                          color="info"
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                       <FaDownload className="mr-3"/> Withdraw Rewards
                        </MDButton>
                       
                        </>
                      )}
                    </div>
                  </MDBox>
                  <MDBox pt={3}>
                    <MDBox pr={1} classNane="inputsre">
                      <TextField type="text" label="Search here" onChange={handleInput} />
                    </MDBox>
                    <DataTable
                      table={{ columns: columns, rows: requestData }}
                      // isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {/* {requestData.length > 0 && (
                      <Stack padding={2}>
                        <Pagination onChange={onPaginationTable} count={page} size="large" />
                      </Stack>
                    )} */}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default WithdrawRecords;
