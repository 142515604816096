import React, { useEffect, useState } from "react";
import "./ReadMoreArticle.css";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BACKEND_BASE_URL } from "../../../src/apiInstances/baseurl";

import { CardContent, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { BiArrowBack } from "react-icons/bi";

const ReadMoreArticle = () => {
  const { encryptData, decryptData } = useEncryption();

  const [articles, setArticles] = useState(JSON.parse(localStorage.getItem("readArticle")) || {});
  let navigate = useNavigate();

  const { id } = useParams();
  console.log("🚀 ~ ReadMoreArticle ~ id:", id);
  const [viewdata, setViewData] = useState();
  console.log("🚀 ~ ReadMoreArticle ~ viewdata:", viewdata);
  const viewArticle = () => {
    try {
      axiosInstanceAuth.get(`/admin/viewArticle/${id}`).then((res) => {
        const data = decryptData(res?.data?.data);
        console.log("🚀 ~ //.then ~ data:", data);
        setViewData(data?.data);
      });
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    viewArticle();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className=" mx-32 text-center bg-gradient-to-r bg-slate-200 rounded-xl">
                    <CardContent className="">
                      
                      <div
                        className="cursor-pointer flex items-center gap-3 mt-5 text-3xl"
                        onClick={() => navigate("/addnews")}
                      >
                        <BiArrowBack />
                        Back
                      </div>
                    </CardContent>
                    <div className="inline-block text-center ">
                      <img
                        src={`${BACKEND_BASE_URL}/${viewdata?.image}`}
                        alt="article"
                        className="mt-10"
                      />
                    </div>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {viewdata?.title}
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: viewdata?.content,
                        }}
                      />
                    </CardContent>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ReadMoreArticle;
