import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import "./addnode.css";
import NodeDetails from "layouts/nodedetails";

function AddNode() {
  let navigate = useNavigate();
  const [title, setTitle] = useState("smart node");
  const [price, setPrice] = useState("");

  const { encryptData, decryptData } = useEncryption();

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const userInput = (event) => {
    setPrice(event.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      const encryptedData = encryptData(
        JSON.stringify({
          quantity: price,
          name: title,
        })
      );
      axiosInstanceAuth
        .post(`/admin/dummyRemaining`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);

          if (responseData.status) {
            toast.success(responseData.message);
          }
        })
        .catch((res) => {
          let err = decryptData(res.data.data);
          console.log("🚀 ~ err", err);
          toast.error(err.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                Change Remaining Nodes
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  {/* <InputLabel id="demo-simple-select-label">Choose Node</InputLabel>
                  <NativeSelect
                    className="box-height"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={title}
                    label="Title"
                    onChange={handleChange}
                    required
                  >
                    <option value={"power node"}>power node</option>
                    <option value={"smart node"}>smart node</option>
                    <option value={"master node"}>master node</option>
                  </NativeSelect> */}

                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput type="text" name="UserType" label="Node" value={title} />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput type="text" name="UserType" label="Quantity" onChange={userInput} />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        // onClick={submitHandler}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        {/* View Nodes Start */}
        <NodeDetails />
        {/* View Nodes End */}

        <Footer />
      </DashboardLayout>
    </>
  );
}

export default AddNode;
