import { axiosInstanceAuth } from "apiInstances";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { ButtonGroup, Button } from "@mui/material";

const ViewOneUser = () => {
  const getPath = useLocation().pathname.split("/")?.[3];
  const { encryptData, decryptData } = useEncryption();
  const [viewData, setViewData] = useState([]);
  const [selectedButton, setSelectedButton] = useState("one");

  const [rows, setRows] = useState([]);
  console.log("🚀 ~ ViewOneUser ~ rows:", rows);
  const [rows2, setRows2] = useState([]);

  const totalNodes = rows
    ?.map((x) => Number(x?.nodes?.props?.children))
    .reduce((sum, current) => sum + current, 0);
  const totalNodes1 = rows2
    ?.map((x) => Number(x?.nodes?.props?.children))
    .reduce((sum, current) => sum + current, 0);

  const totalAirTickets = rows
    ?.map((x) => Number(x?.airTickets?.props?.children))
    .reduce((sum, current) => sum + current, 0);
  const totalAirTickets1 = rows2
    ?.map((x) => Number(x?.airTickets?.props?.children))
    .reduce((sum, current) => sum + current, 0);

  const totalNodeRewards = rows
    ?.map((x) => Number(x?.rewards?.props?.children))
    .reduce((sum, current) => sum + current, 0);
  const totalNodeRewards1 = rows2
    ?.map((x) => Number(x?.rewards?.props?.children))
    .reduce((sum, current) => sum + current, 0);

  const totalAirRewards = rows
    ?.map((x) => Number(x?.airrewards?.props?.children))
    .reduce((sum, current) => sum + current, 0);
  const totalAirRewards1 = rows2
    ?.map((x) => Number(x?.airrewards?.props?.children))
    .reduce((sum, current) => sum + current, 0);

  const totalabn = rows
    ?.map((x) => Number(x?.abn?.props?.children))
    .reduce((sum, current) => sum + current, 0);
  const totalabn1 = rows2
    ?.map((x) => Number(x?.abn?.props?.children))
    .reduce((sum, current) => sum + current, 0);

  const totalAbnRewards = rows
    ?.map((x) => Number(x?.abnrewards?.props?.children))
    .reduce((sum, current) => sum + current, 0);
  const totalAbnRewards1 = rows2
    ?.map((x) => Number(x?.abnrewards?.props?.children))
    .reduce((sum, current) => sum + current, 0);

  const [rows1, setRows1] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const viewUserData = (data) => {
    navigate(`/admin/viewReferral/${data._id}`);
  };

  const viewUser = async () => {
    await axiosInstanceAuth
      .get(`admin/viewReferral/${getPath}`)
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        const responseData = decryptData(res?.data?.data);
        console.log("🚀 ~ .then ~ responseData:", responseData);

        setViewData(responseData);
        const temp = [];

        responseData?.formattedData?.map((data, index) => {
          temp.push({
            id: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {index + 1}
              </MDTypography>
            ),
            username: (
              <div className="userDetails" onClick={() => viewUserData(data)}>
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {`${data.username}`}
                </MDTypography>
              </div>
            ),

            nodes: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {/* {`${data.nodes || 0}`} */}
                {data?.nodes?.smartNode +
                  data?.nodes?.powerNode +
                  data?.nodes?.masterNode}
                ({data?.nodes?.smartNode}+{data?.nodes?.powerNode}+
                {data?.nodes?.masterNode})
              </MDTypography>
            ),
            airTickets: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {/* {`${data.airTickets || 0}`} */}
                {data?.airTickets?.BClass +
                  data?.airTickets?.EClass +
                  data?.airTickets?.FClass}
                ({data?.airTickets?.BClass}+{data?.airTickets?.EClass}+
                {data?.airTickets?.FClass})
              </MDTypography>
            ),
            abn: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.abn || 0}`}
              </MDTypography>
            ),
            rewards: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.rewards || 0}`}
              </MDTypography>
            ),
            airrewards: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.airrewards || 0}`}
              </MDTypography>
            ),
            abnrewards: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.airBounrnNodeRewards || 0}`}
              </MDTypography>
            ),
          });
        });
        setRows(temp);
        const temp2 = [];

        responseData?.formattedData1?.map((data, index) => {
          temp2.push({
            id: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {index + 1}
              </MDTypography>
            ),
            username: (
              <div className="userDetails" onClick={() => viewUserData(data)}>
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {`${data.username}`}
                </MDTypography>
              </div>
            ),

            nodes: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {/* {`${data.nodes || 0}`} */}
                {data?.nodes?.smartNode +
                  data?.nodes?.powerNode +
                  data?.nodes?.masterNode}
                ({data?.nodes?.smartNode}+{data?.nodes?.powerNode}+
                {data?.nodes?.masterNode})
              </MDTypography>
            ),
            airTickets: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {/* {`${data.airTickets || 0}`} */}
                {data?.airTickets?.BClass +
                  data?.airTickets?.EClass +
                  data?.airTickets?.FClass}
                ({data?.airTickets?.BClass}+{data?.airTickets?.EClass}+
                {data?.airTickets?.FClass})
              </MDTypography>
            ),
            abn: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.abn || 0}`}
              </MDTypography>
            ),
            rewards: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.rewards || 0}`}
              </MDTypography>
            ),
            airrewards: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.airrewards || 0}`}
              </MDTypography>
            ),
            abnrewards: (
              <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                {`${data.airBounrnNodeRewards || 0}`}
              </MDTypography>
            ),
          });
        });
        setRows2(temp2);

        const temp1 = [];

        temp1?.push({
          username1: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].username}`}
            </MDTypography>
          ),

          email: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].email}`}
            </MDTypography>
          ),

          totalnodes1: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].nodes}` || 0}
            </MDTypography>
          ),
          airTickets1: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].airTickets || 0}`}
            </MDTypography>
          ),
          abn: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].abn || 0}`}
            </MDTypography>
          ),
          rewards1: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].totalRewards || 0}`}
            </MDTypography>
          ),
          airrewards1: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].totalAirRewards || 0}`}
            </MDTypography>
          ),
          abnrewards: (
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${responseData.data[0].airBounrnNodeRewards || 0}`}
            </MDTypography>
          ),
        });
        setRows1(temp1);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    viewUser();
  }, [getPath]);

  const columns = [
    { Header: `id (${rows.length})`, accessor: "id", align: "left" },
    { Header: "Username", accessor: "username", align: "left" },
    {
      Header: `Nodes (${selectedButton === "one" ? totalNodes : totalNodes1})`,
      accessor: "nodes",
      align: "left",
    },
    {
      Header: `Air Tickets (${selectedButton === "one" ? totalAirTickets : totalAirTickets1})`,
      accessor: "airTickets",
      align: "left",
    },
    {
      Header: `Air Bourne Node (${selectedButton === "one" ? totalabn : totalabn1})`,
      accessor: "abn",
      align: "left",
    },
    {
      Header: `Node Rewards (${selectedButton === "one" ? totalNodeRewards : totalNodeRewards1})`,
      accessor: "rewards",
      align: "left",
    },
    {
      Header: `AirTicket Rewards (${selectedButton === "one" ? totalAirRewards : totalAirRewards1
        })`,
      accessor: "airrewards",
      align: "left",
    },
    {
      Header: `Total ABN Rewards (${selectedButton === "one" ? totalAbnRewards : totalAbnRewards1
        })`,
      accessor: "abnrewards",
      align: "left",
    },
  ];

  const columns1 = [
    { Header: "Username", accessor: "username1", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: `Nodes`, accessor: "totalnodes1", align: "left" },
    { Header: "Air Tickets", accessor: "airTickets1", align: "left" },
    { Header: "Air Bourne Node", accessor: "abn", align: "left" },

    { Header: "Total Node Rewards", accessor: "rewards1", align: "left" },
    { Header: "Total AirTicket Rewards", accessor: "airrewards1", align: "left" },
    { Header: "Total ABN Rewards", accessor: "abnrewards", align: "left" },
  ];

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <>
            <MDBox pt={3}>
              <DataTable
                table={{ columns: columns1, rows: rows1 }}
                isSorted={true}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>

            <MDBox pt={6} pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="coinTitleColor"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Referral Code Details of {viewData?.data[0].username}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <div className="text-center  mt-5">
                <ButtonGroup variant="contained" color="primary">
                  <Button
                    onClick={() => setSelectedButton("one")}
                    variant={selectedButton === "one" ? "contained" : "outlined"}
                    style={{
                      backgroundColor: selectedButton === "one" ? "blue" : "white",
                      color: selectedButton === "one" ? "white" : "blue",
                    }}
                  >
                    Level One
                  </Button>
                  <Button
                    onClick={() => setSelectedButton("two")}
                    variant={selectedButton === "two" ? "contained" : "outlined"}
                    style={{
                      backgroundColor: selectedButton === "two" ? "blue" : "white",
                      color: selectedButton === "two" ? "white" : "blue",
                    }}
                  >
                    Level Two
                  </Button>
                </ButtonGroup>
              </div>
              <MDBox pt={3}>
                {selectedButton === "one" ? (
                  <>
                    <DataTable
                      table={{ columns: columns, rows: rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </>
                ) : (
                  <DataTable
                    table={{ columns: columns, rows: rows2 }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </MDBox>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default ViewOneUser;
