import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import NodeDetails from "layouts/nodedetails";
import {
  MenuItem,

  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Button from '@material-ui/core/Button';



import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

function SetRewards() {
  let navigate = useNavigate();
  const [title, setTitle] = useState("smart node");
  const [rewardsPerNode, setRewardsPerNode] = useState("");
  const [selectedValue, setSelectedValue] = useState('smart node');

  const { encryptData, decryptData } = useEncryption();

  const [name, setName] = useState({
    Smart: true,
    Power: false,
    Master: false,
    All: false,
  });
 
  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const userInput = (event) => {
    setRewardsPerNode(event.target.value);
  };
  // name, currency, price
  const submitHandler = async (e) => {
    e.preventDefault();

    try {

    await  axiosInstanceAuth
        .post(`/admin/setRewards`, {
          perNodeReward: Number(rewardsPerNode),
          name: selectedValue,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          
          if (responseData.status == true) {
            toast.success(responseData.message);
            setRewardsPerNode("")
          }
          else {
            toast.warn(responseData.message);
            setRewardsPerNode("")

          }
        })
        .catch((res) => {
          let err = decryptData(res.data.data);
          toast.error(err.message);
        });
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  };


  const handleNameChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Set Rewards
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl component="form" onSubmit={submitHandler}>
                  <MDBox component="div" role="form">

                    <MDBox mb={2}>
                      <MenuItem required>
                        <RadioGroup row value={selectedValue} onChange={handleNameChange}>
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="smart node"
                            label="Smart"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="power node"
                            label="Power"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="master node"
                            label="Master"
                          />
                          {/* <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="all"
                            label="All"
                          /> */}
                        </RadioGroup>
                      </MenuItem>
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput type="text" name="UserType" label="Node" value={selectedValue} />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards per node"
                        onChange={userInput}
                        value={rewardsPerNode}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                    <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        {/* View Nodes Start */}
        <NodeDetails />
        {/* View Nodes End */}
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default SetRewards;
