// import * as XLSX from "xlsx";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// import { useNavigate } from "react-router-dom";
// import { useEffect, useRef, useState } from "react";
// import { axiosInstanceAuth } from "../../apiInstances/index";
// import useEncryption from "EncryptDecrypt/EncryptDecrypt";
// import { toast } from "react-toastify";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import CloseIcon from "@mui/icons-material/Close";
// import {
//   Button,
//   ButtonGroup,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   TextField,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import MDButton from "components/MDButton";
// import "./user.css";
// import { FaDownload } from "react-icons/fa6";
// import { useMaterialUIController, setLoadingTable } from "context";

// const User = () => {
//   const { encryptData, decryptData } = useEncryption();
//   let navigate = useNavigate();
//   const [searchInput, setSearchInput] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [page, setPage] = useState(1);
//   console.log("🚀 ~ User ~ page:", page);
//   const [requestData, setRequestData] = useState([]);
//   const [userTableData, setuserTableData] = useState([]);

//   const [oldRequestData, setOldRequestData] = useState([]);
//   const [entriesPerPage] = useState(20);
//   const [loading1, setLoading1] = useState(false);

//   const [loading, setLoading] = useState(false);
//   const [isBlock, setIsBlock] = useState([]);
//   const [openEditPopUp, setOpenEditPopUp] = useState(false);
//   const [isEdit, setIsEdit] = useState([]);
//   const [nodes, setNodes] = useState([{}]);

//   const [filter, setFilter] = useState({});

//   const [data, setData] = useState([]);
//   const [searchData, setsearchData] = useState();
//   const [searchTerm, setSearchTerm] = useState("");

//   const [searchFinal, setSearchFinal] = useState("");

//   const [debounceTimeout, setDebounceTimeout] = useState(null);

//   const [controller, dispatch] = useMaterialUIController();

//   const [selectedButton, setSelectedButton] = useState("All");
//   const [AllUsersData, setAllUsersData] = useState();
//   const [smartAffiliateStatusData, setSmartAffiliateStatusData] = useState([]);
//   const [powerAffiliateStatusData, setPowerAffiliateStatusData] = useState([]);
//   const [masterAffiliateStatusData, setMasterAffiliateStatusData] = useState([]);

//   // const aff = async () => {
//   //   const res = await axiosInstanceAuth.get("/admin/affiliateStatus")
//   //   const responseData = decryptData(res?.data?.data);
//   //   console.log("🚀 ~ aff ~ aff aff:", responseData)
//   // }
//   // useEffect(() => {
//   //   aff()
//   // }, [])

//   const { loadingTable } = controller;
//   const handleChange = (e) => {
//     setFilter({ [e.target.name]: e.target.value });
//   };

//   const filteredData = data.filter((obj) => {
//     for (let key in filter) {
//       if (!obj.hasOwnProperty(key) || obj[key] !== filter[key]) {
//         return false;
//       }
//     }
//     return true;
//   });

//   const checkToken = () => {
//     const Token = localStorage.getItem("Token");
//     if (!Token) {
//       navigate("/sign-in");
//     }
//   };

//   const viewUser = (data) => {
//     navigate(`/admin/viewReferral/${data._id}`);
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const handleEdit = (data) => {
//     setIsEdit([data]);
//     setOpenEditPopUp(true);
//   };
//   const handleCloseEditPopUp = () => {
//     setOpenEditPopUp(false);
//   };

//   const getUser = async () => {
//     setLoading(true);

//     try {
//       await axiosInstanceAuth
//         .get(`/admin/viewUsers/${currentPage}`)
//         .then((res) => {
//           const responseData = decryptData(res?.data?.data);
//           console.log("🚀 ~ .then ~ responseData:", responseData);
//           const totalPages = responseData?.totalPages;
//           setPage(totalPages);

//           const start = (currentPage - 1) * entriesPerPage;
//           const end = start + entriesPerPage;
//           const temp = responseData?.data?.map((data, index) => {
//             return {
//               id: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {start + index + 1}
//                 </MDTypography>
//               ),
//               full_name: (
//                 <div className="userDetails" onClick={() => viewUser(data)}>
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {`${data.username} `}
//                   </MDTypography>
//                 </div>
//               ),
//               email: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {data.email}
//                 </MDTypography>
//               ),
//               referralCode: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data.referralCode}
//                 </MDTypography>
//               ),
//               node: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {/* {data.nodes} */}
//                   {setNodes((prev) => [...prev, data.nodes])}
//                   {data?.totalNodes?.smartNode +
//                     data?.totalNodes?.powerNode +
//                     data?.totalNodes?.masterNode}
//                   ({data?.totalNodes?.smartNode}+{data?.totalNodes?.powerNode}+
//                   {data?.totalNodes?.masterNode})
//                 </MDTypography>
//               ),
//               nftAirTickets: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data?.totalTicket?.bClass +
//                     data?.totalTicket?.eClass +
//                     data?.totalTicket?.fClass}
//                   ({data?.totalTicket?.bClass}+{data?.totalTicket?.eClass}+
//                   {data?.totalTicket?.fClass})
//                 </MDTypography>
//               ),
//               AbtairTicket: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data.totalAbtTicket}
//                 </MDTypography>
//               ),
//               affiliateStatus: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data?.affiliate}
//                 </MDTypography>
//               ),
//               fixwallet: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data?.fixwalletaddress}
//                 </MDTypography>
//               ),
//               totalWithdraw: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data.totalWithdraw}
//                 </MDTypography>
//               ),
//               create: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {new Date(data.createdAt).toLocaleDateString("en-US", {
//                     year: "numeric",
//                     month: "short",
//                     day: "numeric",
//                   })}
//                 </MDTypography>
//               ),
//               lastlogin: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data.lastLogged === "" ? (
//                     <div className="desh"> ------------ </div>
//                   ) : (
//                     <div>
//                       {new Date(data.lastLogged).toLocaleDateString("en-US", {
//                         year: "numeric",
//                         month: "short",
//                         day: "numeric",
//                       })}
//                     </div>
//                   )}
//                 </MDTypography>
//               ),

//               rewards: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data.rewards}
//                 </MDTypography>
//               ),
//               blockWithdraw: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   <MDButton
//                     variant="gradient"
//                     color="info"
//                     fullWidth
//                     type="submit"
//                     className="TitleColor"
//                     onClick={() => blockWithdraw(data)}
//                   >
//                     {`${data.blockWithdraw}`}
//                   </MDButton>
//                 </MDTypography>
//               ),

//               blockApp: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   <MDButton
//                     variant="gradient"
//                     color="info"
//                     fullWidth
//                     type="submit"
//                     className="TitleColor"
//                     onClick={() => blockuser(data)}
//                   >
//                     {`${data.status}`}
//                   </MDButton>
//                 </MDTypography>
//               ),
//             };
//           });
//           setuserTableData(temp);
//           setRequestData(temp);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.warn(error.message);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const affiliateProcessData = (apiResponse, apiStatus) => {
//     // console.log("🚀 ~ affiliateProcessData ~ apiResponse:", apiResponse)
//     // console.log("🚀 ~ affiliateProcessData ~ apiStatus:", apiStatus)
//     const temp = apiResponse?.map((data, index) => {
//       return {
//         id: (
//           <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//             {index + 1}
//           </MDTypography>
//         ),
//         full_name: (
//           <div className="userDetails" onClick={() => viewUser(data)}>
//             <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//               {`${data?.username} `}
//             </MDTypography>
//           </div>
//         ),
//         email: (
//           <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//             {data?.email}
//           </MDTypography>
//         ),
//         referralCode: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data?.referralCode}
//           </MDTypography>
//         ),
//         node: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {/* {data.nodes} */}
//             {data?.totalNodes?.smartNode +
//               data?.totalNodes?.powerNode +
//               data?.totalNodes?.masterNode}
//             ({data?.totalNodes?.smartNode}+{data?.totalNodes?.powerNode}+
//             {data?.totalNodes?.masterNode})
//           </MDTypography>
//         ),
//         nftAirTickets: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data?.totalTicket?.bClass + data?.totalTicket?.eClass + data?.totalTicket?.fClass}(
//             {data?.totalTicket?.bClass}+{data?.totalTicket?.eClass}+{data?.totalTicket?.fClass})
//           </MDTypography>
//         ),
//         AbtairTicket: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data?.totalAbtTicket}
//           </MDTypography>
//         ),
//         affiliateStatus: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data?.affiliate}
//           </MDTypography>
//         ),
//         fixwallet: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data?.fixwalletaddress}
//           </MDTypography>
//         ),
//         totalWithdraw: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data?.totalWithdraw}
//           </MDTypography>
//         ),
//         create: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {new Date(data.createdAt).toLocaleDateString("en-US", {
//               year: "numeric",
//               month: "short",
//               day: "numeric",
//             })}
//           </MDTypography>
//         ),
//         lastlogin: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data.lastLogged === "" ? (
//               <div className="desh"> ------------ </div>
//             ) : (
//               <div>
//                 {new Date(data.lastLogged).toLocaleDateString("en-US", {
//                   year: "numeric",
//                   month: "short",
//                   day: "numeric",
//                 })}
//               </div>
//             )}
//           </MDTypography>
//         ),

//         rewards: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             {data?.rewards}
//           </MDTypography>
//         ),
//         blockWithdraw: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             <MDButton
//               variant="gradient"
//               color="info"
//               fullWidth
//               type="submit"
//               className="TitleColor"
//               onClick={() => blockWithdraw(data)}
//             >
//               {`${data.blockWithdraw}`}
//             </MDButton>
//           </MDTypography>
//         ),

//         blockApp: (
//           <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//             <MDButton
//               variant="gradient"
//               color="info"
//               fullWidth
//               type="submit"
//               className="TitleColor"
//               onClick={() => blockuser(data)}
//             >
//               {`${data.status}`}
//             </MDButton>
//           </MDTypography>
//         ),
//       };
//     });

//     if (apiStatus === "smart") {
//       setSmartAffiliateStatusData(temp);
//     }
//     if (apiStatus === "power") {
//       setPowerAffiliateStatusData(temp);
//     }
//     if (apiStatus === "master") {
//       setMasterAffiliateStatusData(temp);
//     }

//     // apiStatus === "smart" ? setSmartAffiliateStatusData(temp) : apiStatus === "power" ? setPowerAffiliateStatusData(temp) : setMasterAffiliateStatusData(temp);

//     setLoading(false);
//   };

//   const affiliateStatusData = async () => {
//     setLoading(true);

//     try {
//       await axiosInstanceAuth
//         .get(`/admin/affiliateStatus`)
//         .then((res) => {
//           const responseData = decryptData(res?.data?.data);

//           const start = (currentPage - 1) * entriesPerPage;
//           const end = start + entriesPerPage;
//           affiliateProcessData(responseData?.data?.smartAffiliateData, "smart");
//           affiliateProcessData(responseData?.data?.powerAffiliateData, "power");
//           affiliateProcessData(responseData?.data?.masterAffiliateData, "master");
//         })
//         .catch((error) => {
//           console.warn(error.message);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const blockWithdraw = async (data) => {
//     try {
//       const encryptedData = encryptData(
//         JSON.stringify({
//           email: data.email,
//         })
//       );
//       await axiosInstanceAuth
//         .post(`/admin/blockWithdraw`, {
//           data: encryptedData,
//         })
//         .then(async (res) => {
//           const responseData = decryptData(res.data.data);
//           toast.success(responseData.message);
//           await getUser();
//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const downloadReport = async () => {
//     setLoading1(true);
//     // console.log("downloadReport----------------------------");

//     try {
//       let page = 1;
//       let allData = [];

//       while (true) {
//         const response = await axiosInstanceAuth.get(`admin/download/${page}`);
//         const responseData = decryptData(response?.data?.data);
//         // console.log("🚀 ~ downloadReport ~ responseData:", responseData);

//         if (!responseData?.data || responseData.data.length === 0) {
//           // No more data to fetch, break the loop
//           break;
//         }

//         allData = [...allData, ...responseData?.data];
//         // console.log("🚀 ~ downloadReport ~ allData:", allData);
//         page++;
//       }

//       const formattedData = allData?.map((data) => ({
//         name: data?.username,
//         email: data?.email,
//         referralCode: data?.referralCode,

//         node: data?.totalNodes
//           ? `${
//               data?.totalNodes.smartNode + data?.totalNodes.powerNode + data?.totalNodes.masterNode
//             } (${data?.totalNodes.smartNode} + ${data?.totalNodes.powerNode} + ${
//               data?.totalNodes.masterNode
//             })`
//           : null,
//         nftAirTickets: data?.totalTicket
//           ? `${data?.totalTicket.bClass + data?.totalTicket.eClass + data?.totalTicket.fClass} (${
//               data?.totalTicket.bClass
//             } + ${data?.totalTicket.eClass} + ${data?.totalTicket.fClass})`
//           : null,
//         totalWithdraw: data?.totalWithdraw,
//         createdAt: new Date(data?.createdAt).toLocaleDateString("en-US", {
//           year: "numeric",
//           month: "short",
//           day: "numeric",
//         }),
//         lastLoginned: data?.lastLogged
//           ? new Date(data.lastLogged).toLocaleDateString("en-US", {
//               year: "numeric",
//               month: "short",
//               day: "numeric",
//             })
//           : null,
//         Reward: data?.rewards,
//         BlockWithdraw: data?.blockWithdraw,
//         AppBlock: data?.status,
//       }));
//       const workSheet = XLSX.utils.json_to_sheet(formattedData);
//       const workBook = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(workBook, workSheet, "users");

//       // Buffer
//       let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
//       // Binary string
//       XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
//       // Download
//       XLSX.writeFile(workBook, "userData.xlsx");

//       setLoading1(false);
//     } catch (error) {
//       toast.error(error.message || "An error occurred");
//       setLoading1(false);
//     }
//   };

//   const blockuser = async (data) => {
//     setIsBlock(data);

//     try {
//       const encryptedData = encryptData(
//         JSON.stringify({
//           email: data.email,
//         })
//       );
//       await axiosInstanceAuth
//         .post(`/admin/blockUser`, {
//           data: encryptedData,
//         })
//         .then(async (res) => {
//           const responseData = decryptData(res.data.data);
//           toast.success(responseData.message);
//           await getUser();
//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const columns = [
//     { Header: "id", accessor: "id", align: "left" },
//     { Header: "User Name", accessor: "full_name", align: "left" },
//     { Header: "email", accessor: "email", align: "left" },
//     { Header: "Referred By", accessor: "referralCode", align: "left" },
//     { Header: "Owned Nodes (sn+pn+mn)", accessor: "node", align: "left" },
//     { Header: "NFT AirTickets (bc+ec+fc)", accessor: "nftAirTickets", align: "left" },
//     { Header: "Abt Air Ticket", accessor: "AbtairTicket", align: "left" },
//     { Header: "Affiliate", accessor: "affiliateStatus", align: "left" },
//     { Header: "Fixed Wallet Address", accessor: "fixwallet", align: "left" },

//     { Header: "Total Withdraw", accessor: "totalWithdraw", align: "left" },
//     { Header: "Created  On", accessor: "create", align: "left" },
//     {
//       Header: () => (
//         <div>
//           Last <br /> Login
//         </div>
//       ),
//       accessor: "lastlogin",
//       align: "left",
//     },
//     { Header: "Rewards", accessor: "rewards", align: "left" },
//     { Header: "Block Withdraw", accessor: "blockWithdraw", align: "left" },
//     { Header: "App Block", accessor: "blockApp", align: "left" },
//   ];

//   const handleInput = (e) => {
//     const searchdata = e.target.value.trim();
//     setSearchTerm(searchdata);

//     if (debounceTimeout) {
//       clearTimeout(debounceTimeout);
//     }
//     const newDebounceTimeout = setTimeout(async () => {
//       if (searchdata === "") {
//         getUser();
//       } else {
//         try {
//           setLoadingTable(dispatch, true);
//           // setLoading(true)
//           await axiosInstanceAuth
//             .get(`/admin/searchUserByWallet`, {
//               params: {
//                 walletAddress: searchdata,
//                 page: currentPage,
//               },
//             })
//             .then((res) => {
//               let responseData = decryptData(res?.data?.data);

//               // setsearchData(responseData);
//               // setLoading(false)
//               setLoadingTable(dispatch, false);

//               const temp = responseData?.data?.map((data, index) => {
//                 return {
//                   id: (
//                     <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                       {index + 1}
//                     </MDTypography>
//                   ),
//                   full_name: (
//                     <div className="userDetails" onClick={() => viewUser(data)}>
//                       <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                         {`${data.username} `}
//                       </MDTypography>
//                     </div>
//                   ),
//                   email: (
//                     <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                       {data.email}
//                     </MDTypography>
//                   ),
//                   referralCode: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data.referralCode}
//                     </MDTypography>
//                   ),
//                   node: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data?.totalNodes?.smartNode +
//                         data?.totalNodes?.powerNode +
//                         data?.totalNodes?.masterNode}
//                       ({data?.totalNodes?.smartNode}+{data?.totalNodes?.powerNode}+
//                       {data?.totalNodes?.masterNode}) {setNodes((prev) => [...prev, data.nodes])}
//                     </MDTypography>
//                   ),
//                   nftAirTickets: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data?.totalTicket?.bClass +
//                         data?.totalTicket?.eClass +
//                         data?.totalTicket?.fClass}
//                       ({data?.totalTicket?.bClass}+{data?.totalTicket?.eClass}+
//                       {data?.totalTicket?.fClass})
//                     </MDTypography>
//                   ),
//                   AbtairTicket: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data.totalAbtTicket}
//                     </MDTypography>
//                   ),
//                   affiliateStatus: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data?.affiliate}
//                     </MDTypography>
//                   ),
//                   fixwallet: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data?.fixwalletaddress}
//                     </MDTypography>
//                   ),
//                   totalWithdraw: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data.totalWithdraw}
//                     </MDTypography>
//                   ),
//                   create: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {new Date(data.createdAt).toLocaleDateString("en-US", {
//                         year: "numeric",
//                         month: "short",
//                         day: "numeric",
//                       })}
//                     </MDTypography>
//                   ),
//                   lastlogin: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data.lastLogged === "" ? (
//                         <div className="desh"> ------------ </div>
//                       ) : (
//                         <div>
//                           {new Date(data.lastLogged).toLocaleDateString("en-US", {
//                             year: "numeric",
//                             month: "short",
//                             day: "numeric",
//                           })}
//                         </div>
//                       )}
//                     </MDTypography>
//                   ),

//                   rewards: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       {data.rewards}
//                     </MDTypography>
//                   ),
//                   blockWithdraw: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       <MDButton
//                         variant="gradient"
//                         color="info"
//                         fullWidth
//                         type="submit"
//                         className="TitleColor"
//                         onClick={() => blockWithdraw(data)}
//                       >
//                         {`${data.blockWithdraw}`}
//                       </MDButton>
//                     </MDTypography>
//                   ),

//                   blockApp: (
//                     <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                       <MDButton
//                         variant="gradient"
//                         color="info"
//                         fullWidth
//                         type="submit"
//                         className="TitleColor"
//                         onClick={() => blockuser(data)}
//                       >
//                         {`${data.status}`}
//                       </MDButton>
//                     </MDTypography>
//                   ),
//                 };
//               });
//               setuserTableData(temp);
//               // setRequestData(temp);
//               setPage(1);
//               setCurrentPage(1);
//             });
//         } catch (error) {
//           console.error(error);
//         }
//       }
//     }, 500);

//     setDebounceTimeout(newDebounceTimeout);
//   };

//   const onPaginationTable = (event, page) => {
//     setCurrentPage(page);
//   };

//   const viewUserDetails = (data) => {
//     localStorage.setItem("profile", JSON.stringify(data));
//     navigate("/profile");
//   };

//   const handlePageChange = (event, newPage) => {
//     if (typeof newPage === "number") {
//       setCurrentPage(newPage);
//     }
//   };
//   const Token = localStorage.getItem("Token");
//   useEffect(() => {
//     if (Token) {
//       getUser();
//     } else {
//       navigate("/sign-in");
//     }
//   }, [currentPage]);

//   useEffect(() => {
//     affiliateStatusData();
//   }, []);

//   const setDataTableData = (btnName) => {
//     setSelectedButton(btnName);
//     btnName === "smart"
//       ? setuserTableData(smartAffiliateStatusData)
//       : btnName === "power"
//       ? setuserTableData(powerAffiliateStatusData)
//       : btnName === "master"
//       ? setuserTableData(masterAffiliateStatusData)
//       : setuserTableData(requestData);
//   };
//   return (
//     <>
//       <DashboardNavbar />
//       <DashboardLayout>
//         {loading ? (
//           <div className="snippet" data-title=".dot-spin">
//             <div className="stage">
//               <div className="dot-spin"></div>
//             </div>
//           </div>
//         ) : (
//           <MDBox pt={6} pb={3}>
//             <Grid container spacing={3}>
//               <Grid item xs={12}>
//                 <Card>
//                   <MDBox
//                     mx={2}
//                     mt={-3}
//                     py={3}
//                     px={2}
//                     variant="gradient"
//                     bgColor="coinTitleColor"
//                     borderRadius="lg"
//                     coloredShadow="info"
//                   >
//                     <div className="box-user">
//                       <MDTypography variant="h6" color="white">
//                         User
//                       </MDTypography>
//                       {loading1 ? (
//                         <>
//                           <div className="flex justify-center items-center gap-2">
//                             <div className="text-yellow-200 font-bold mr-5">
//                               It takes a few time due to heavy load.{" "}
//                             </div>
//                             <div className="dot-spin1"></div>
//                             <MDButton
//                               // onClick={downloadReport}
//                               variant="contained"
//                               color="info"
//                               sx={{ display: "flex", justifyContent: "center" }}
//                               disabled={loading1}
//                             >
//                               Downloading ..
//                             </MDButton>
//                           </div>
//                         </>
//                       ) : (
//                         <>
//                           <MDButton
//                             onClick={() => downloadReport()}
//                             variant="contained"
//                             color="info"
//                             sx={{ display: "flex", justifyContent: "center" }}
//                           >
//                             <FaDownload className="mr-3" /> Download User
//                           </MDButton>
//                         </>
//                       )}
//                     </div>
//                   </MDBox>
//                   <div className="text-center  mt-5">
//                     <ButtonGroup variant="contained" color="primary">
//                       <Button
//                         onClick={() => setDataTableData("smart")}
//                         variant={selectedButton === "smart" ? "contained" : "outlined"}
//                         style={{
//                           backgroundColor: selectedButton === "smart" ? "blue" : "white",
//                           color: selectedButton === "smart" ? "white" : "blue",
//                         }}
//                       >
//                         Smart
//                       </Button>
//                       <Button
//                         onClick={() => setDataTableData("power")}
//                         variant={selectedButton === "power" ? "contained" : "outlined"}
//                         style={{
//                           backgroundColor: selectedButton === "power" ? "blue" : "white",
//                           color: selectedButton === "power" ? "white" : "blue",
//                         }}
//                       >
//                         Power
//                       </Button>
//                       <Button
//                         onClick={() => setDataTableData("master")}
//                         variant={selectedButton === "master" ? "contained" : "outlined"}
//                         style={{
//                           backgroundColor: selectedButton === "master" ? "blue" : "white",
//                           color: selectedButton === "master" ? "white" : "blue",
//                         }}
//                       >
//                         Master
//                       </Button>
//                       <Button
//                         onClick={() => {
//                           setDataTableData("All");
//                         }}
//                         variant={selectedButton === "All" ? "contained" : "outlined"}
//                         style={{
//                           backgroundColor: selectedButton === "All" ? "blue" : "white",
//                           color: selectedButton === "All" ? "white" : "blue",
//                         }}
//                       >
//                         All
//                       </Button>
//                     </ButtonGroup>
//                   </div>
//                   <MDBox pt={3}>
//                     <MDBox className="flex justify-between items-center w-full px-5">
//                       <Stack padding={2}>
//                         <Pagination
//                           onChange={handlePageChange}
//                           count={page}
//                           size="large"
//                           defaultPage={1}
//                           page={currentPage}
//                         />
//                       </Stack>
//                       <TextField
//                         type="text"
//                         label="Search here"
//                         value={searchTerm}
//                         onChange={handleInput}
//                       />
//                     </MDBox>
//                     <hr />
//                     <DataTable
//                       table={{ columns: columns, rows: userTableData }}
//                       isSorted={true}
//                       entriesPerPage={false}
//                       showTotalEntries={false}
//                       noEndBorder
//                     />
//                   </MDBox>
//                 </Card>
//               </Grid>
//             </Grid>
//           </MDBox>
//         )}
//         <Footer />
//       </DashboardLayout>
//       {/* Edit Faiths Form Pop Up */}
//       <Dialog open={openEditPopUp} onClose={handleCloseEditPopUp}>
//         <MDBox className="job-status-form-conatiner">
//           <DialogContent>
//             <DialogContentText>
//               <MDBox className="close-btn">
//                 <MDButton variant="text" color="black">
//                   <CloseIcon onClick={handleCloseEditPopUp} />
//                 </MDButton>
//               </MDBox>
//               <MDBox className="Heading">
//                 <MDTypography variant="h3">Edit</MDTypography>
//               </MDBox>
//               <MDBox component="form" role="form" className="form-content">
//                 <div>
//                   {isEdit.map((item) => (
//                     <div>
//                       <MDBox mb={2}>
//                         <MDInput
//                           type="email"
//                           name="userEmail"
//                           label="Email"
//                           fullWidth
//                           value={item.email}
//                           // onChange={userInput}
//                           required
//                         />
//                       </MDBox>

//                       <MDBox mb={2}>
//                         <MDInput
//                           type="password"
//                           name="UserPassword"
//                           label="Password"
//                           fullWidth
//                           // onChange={userInput}
//                         />
//                       </MDBox>
//                     </div>
//                   ))}
//                 </div>
//               </MDBox>
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <MDButton
//               variant="contained"
//               color="info"
//               sx={{ display: "flex", justifyContent: "center" }}
//             >
//               Update
//             </MDButton>
//           </DialogActions>
//         </MDBox>
//       </Dialog>
//     </>
//   );
// };

// export default User;

import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { axiosInstanceAuth } from "../../apiInstances/index";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import "./user.css";
import { FaDownload, FaRegCopy } from "react-icons/fa6";
import { useMaterialUIController, setLoadingTable } from "context";

const User = () => {
  const { encryptData, decryptData } = useEncryption();
  let navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [requestData, setRequestData] = useState([]);
  const [userTableData, setuserTableData] = useState([]);

  const [oldRequestData, setOldRequestData] = useState([]);
  const [entriesPerPage] = useState(20);
  const [loading1, setLoading1] = useState(false);

  const [loading, setLoading] = useState(false);
  console.log("🚀 ~ User ~ loading:", loading)
  const [isBlock, setIsBlock] = useState([]);
  const [openEditPopUp, setOpenEditPopUp] = useState(false);
  const [isEdit, setIsEdit] = useState([]);
  const [nodes, setNodes] = useState([{}]);

  const [filter, setFilter] = useState({});

  const [data, setData] = useState([]);
  const [searchData, setsearchData] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const [searchFinal, setSearchFinal] = useState("");

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [controller, dispatch] = useMaterialUIController();

  const [selectedButton, setSelectedButton] = useState("All");
  const [AllUsersData, setAllUsersData] = useState();
  const [smartAffiliateStatusData, setSmartAffiliateStatusData] = useState([]);
  const [powerAffiliateStatusData, setPowerAffiliateStatusData] = useState([]);
  const [masterAffiliateStatusData, setMasterAffiliateStatusData] = useState([]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Wallet Address Copied");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  // const aff = async () => {
  //   const res = await axiosInstanceAuth.get("/admin/affiliateStatus")
  //   const responseData = decryptData(res?.data?.data);
  //   console.log("🚀 ~ aff ~ aff aff:", responseData)
  // }
  // useEffect(() => {
  //   aff()
  // }, [])

  const { loadingTable } = controller;
  const handleChange = (e) => {
    setFilter({ [e.target.name]: e.target.value });
  };

  const filteredData = data.filter((obj) => {
    for (let key in filter) {
      if (!obj.hasOwnProperty(key) || obj[key] !== filter[key]) {
        return false;
      }
    }
    return true;
  });

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  const viewUser = (data) => {
    navigate(`/admin/viewReferral/${data._id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEdit = (data) => {
    setIsEdit([data]);
    setOpenEditPopUp(true);
  };
  const handleCloseEditPopUp = () => {
    setOpenEditPopUp(false);
  };

  const getUser = async () => {
    setLoading(true);

    try {
      await axiosInstanceAuth
        .get(`/admin/viewUsers/${currentPage}`)
        .then((res) => {
          const responseData = decryptData(res?.data?.data);
          console.log("🚀 ~ .then ~ responseData:", responseData);
          const totalPages = responseData?.totalPages;
          setPage(totalPages);

          const start = (currentPage - 1) * entriesPerPage;
          const end = start + entriesPerPage;
          const temp = responseData?.data?.map((data, index) => {
            return {
              id: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {start + index + 1}
                </MDTypography>
              ),
              full_name: (
                <div className="userDetails" onClick={() => viewUser(data)}>
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data.username} `}
                  </MDTypography>
                </div>
              ),
              email: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {data.email}
                </MDTypography>
              ),
              referralCode: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data.referralCode}
                </MDTypography>
              ),
              node: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {/* {data.nodes} */}
                  {setNodes((prev) => [...prev, data.nodes])}
                  {data?.totalNodes?.smartNode +
                    data?.totalNodes?.powerNode +
                    data?.totalNodes?.masterNode}
                  ({data?.totalNodes?.smartNode}+{data?.totalNodes?.powerNode}+
                  {data?.totalNodes?.masterNode})
                </MDTypography>
              ),
              nftAirTickets: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data?.totalTicket?.bClass +
                    data?.totalTicket?.eClass +
                    data?.totalTicket?.fClass}
                  ({data?.totalTicket?.bClass}+{data?.totalTicket?.eClass}+
                  {data?.totalTicket?.fClass})
                </MDTypography>
              ),

              AbtairTicket: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data.totalAbtTicket}
                </MDTypography>
              ),
              affiliateStatus: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data?.affiliate}
                </MDTypography>
              ),
              fees: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {(
                    (data?.withdrawFees?.nodes ?? 0) +
                    (data?.withdrawFees?.AirTickets ?? 0) +
                    (data?.withdrawFees?.abnCommission ?? 0) +
                    data?.withdrawFees?.abnRewards ?? 0
                  ).toFixed(2)}
                  ({(data?.withdrawFees?.nodes).toFixed(2)}+
                  {(data?.withdrawFees?.AirTickets).toFixed(2)}+
                  {(data?.withdrawFees?.abnCommission).toFixed(2)}+
                  {(data?.withdrawFees?.abnRewards).toFixed(2)})
                </MDTypography>
              ),
              fixwallet: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium" className="flex gap-2 items-center">
                  {data?.fixwalletaddress?.slice(0, 4)}....{data?.fixwalletaddress?.slice(-4)}
                  {data?.fixwalletaddress !=
                    "-" && <FaRegCopy className="cursor-pointer" onClick={() => copyToClipboard(data?.fixwalletaddress)} />}
                </MDTypography>
              ),

              totalWithdraw: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data.totalWithdraw}
                </MDTypography>
              ),
              create: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {new Date(data.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </MDTypography>
              ),
              lastlogin: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data.lastLogged === "" ? (
                    <div className="desh"> ------------ </div>
                  ) : (
                    <div>
                      {new Date(data.lastLogged).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </div>
                  )}
                </MDTypography>
              ),

              rewards: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data.rewards}
                </MDTypography>
              ),
              blockWithdraw: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    className="TitleColor"
                    onClick={() => blockWithdraw(data)}
                  >
                    {`${data.blockWithdraw}`}
                  </MDButton>
                </MDTypography>
              ),

              blockApp: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    className="TitleColor"
                    onClick={() => blockuser(data)}
                  >
                    {`${data.status}`}
                  </MDButton>
                </MDTypography>
              ),
            };
          });
          setuserTableData(temp);
          setRequestData(temp);
          setLoading(false);
        })
        .catch((error) => {
          console.warn(error.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const affiliateProcessData = (apiResponse, apiStatus) => {
    // console.log("🚀 ~ affiliateProcessData ~ apiResponse:", apiResponse)
    // console.log("🚀 ~ affiliateProcessData ~ apiStatus:", apiStatus)
    const temp = apiResponse?.map((data, index) => {
      return {
        id: (
          <MDTypography component="a" variant="button" color="text" fontWeight="medium">
            {index + 1}
          </MDTypography>
        ),
        full_name: (
          <div className="userDetails" onClick={() => viewUser(data)}>
            <MDTypography component="a" variant="button" color="text" fontWeight="medium">
              {`${data?.username} `}
            </MDTypography>
          </div>
        ),
        email: (
          <MDTypography component="a" variant="button" color="text" fontWeight="medium">
            {data?.email}
          </MDTypography>
        ),
        referralCode: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data?.referralCode}
          </MDTypography>
        ),
        node: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {/* {data.nodes} */}
            {data?.totalNodes?.smartNode +
              data?.totalNodes?.powerNode +
              data?.totalNodes?.masterNode}
            ({data?.totalNodes?.smartNode}+{data?.totalNodes?.powerNode}+
            {data?.totalNodes?.masterNode})
          </MDTypography>
        ),
        nftAirTickets: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data?.totalTicket?.bClass + data?.totalTicket?.eClass + data?.totalTicket?.fClass}(
            {data?.totalTicket?.bClass}+{data?.totalTicket?.eClass}+{data?.totalTicket?.fClass})
          </MDTypography>
        ),
        AbtairTicket: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data?.totalAbtTicket}
          </MDTypography>
        ),
        affiliateStatus: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data?.affiliate}
          </MDTypography>
        ),
        fees: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {(
              (data?.withdrawFees?.nodes ?? 0) +
              (data?.withdrawFees?.AirTickets ?? 0) +
              (data?.withdrawFees?.abnCommission ?? 0) +
              data?.withdrawFees?.abnRewards ?? 0
            ).toFixed(2)}
            ({(data?.withdrawFees?.nodes).toFixed(2)}+{(data?.withdrawFees?.AirTickets).toFixed(2)}+
            {(data?.withdrawFees?.abnCommission).toFixed(2)}+
            {(data?.withdrawFees?.abnRewards).toFixed(2)})
          </MDTypography>
        ),
        fixwallet: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data?.fixwalletaddress}
          </MDTypography>
        ),
        totalWithdraw: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data?.totalWithdraw}
          </MDTypography>
        ),
        create: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {new Date(data.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </MDTypography>
        ),
        lastlogin: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data.lastLogged === "" ? (
              <div className="desh"> ------------ </div>
            ) : (
              <div>
                {new Date(data.lastLogged).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </div>
            )}
          </MDTypography>
        ),

        rewards: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {data?.rewards}
          </MDTypography>
        ),
        blockWithdraw: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
              className="TitleColor"
              onClick={() => blockWithdraw(data)}
            >
              {`${data.blockWithdraw}`}
            </MDButton>
          </MDTypography>
        ),

        blockApp: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              type="submit"
              className="TitleColor"
              onClick={() => blockuser(data)}
            >
              {`${data.status}`}
            </MDButton>
          </MDTypography>
        ),
      };
    });

    if (apiStatus === "smart") {
      setSmartAffiliateStatusData(temp);
    }
    if (apiStatus === "power") {
      setPowerAffiliateStatusData(temp);
    }
    if (apiStatus === "master") {
      setMasterAffiliateStatusData(temp);
    }

    // apiStatus === "smart" ? setSmartAffiliateStatusData(temp) : apiStatus === "power" ? setPowerAffiliateStatusData(temp) : setMasterAffiliateStatusData(temp);


  };

  const affiliateStatusData = async () => {
    setLoading(true);

    try {
      await axiosInstanceAuth
        .get(`/admin/affiliateStatus`)
        .then((res) => {
          const responseData = decryptData(res?.data?.data);

          affiliateProcessData(responseData?.data?.smartAffiliateData, "smart");
          affiliateProcessData(responseData?.data?.powerAffiliateData, "power");
          affiliateProcessData(responseData?.data?.masterAffiliateData, "master");
        })
        .catch((error) => {
          console.warn(error.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const blockWithdraw = async (data) => {
    try {
      const encryptedData = encryptData(
        JSON.stringify({
          email: data.email,
        })
      );
      await axiosInstanceAuth
        .post(`/admin/blockWithdraw`, {
          data: encryptedData,
        })
        .then(async (res) => {
          const responseData = decryptData(res.data.data);
          toast.success(responseData.message);
          await getUser();
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const downloadReport = async () => {
    setLoading1(true);
    // console.log("downloadReport----------------------------");

    try {
      let page = 1;
      let allData = [];

      while (true) {
        const response = await axiosInstanceAuth.get(`admin/download/${page}`);
        const responseData = decryptData(response?.data?.data);
        console.log("🚀 ~ downloadReport ~ responseData:", responseData);

        if (!responseData?.data || responseData.data.length === 0) {
          // No more data to fetch, break the loop
          break;
        }

        allData = [...allData, ...responseData?.data];
        // console.log("🚀 ~ downloadReport ~ allData:", allData);
        page++;
      }

      const formattedData = allData?.map((data) => ({
        name: data?.username,
        email: data?.email,
        referralCode: data?.referralCode,

        node: data?.totalNodes
          ? `${data?.totalNodes.smartNode + data?.totalNodes.powerNode + data?.totalNodes.masterNode
          } (${data?.totalNodes.smartNode} + ${data?.totalNodes.powerNode} + ${data?.totalNodes.masterNode
          })`
          : null,
        nftAirTickets: data?.totalTicket
          ? `${data?.totalTicket.bClass + data?.totalTicket.eClass + data?.totalTicket.fClass} (${data?.totalTicket.bClass
          } + ${data?.totalTicket.eClass} + ${data?.totalTicket.fClass})`
          : null,
        ABTAirTickets: data?.abn,
        Affiliate: data?.affiliate,
        WithdrawFee: data?.withdrawFees
          ? `${(data?.withdrawFees?.nodes + data?.withdrawFees?.AirTickets + data?.withdrawFees?.abnCommission + data?.withdrawFees?.abnRewards).toFixed(2)} (${data?.withdrawFees?.nodes.toFixed(2)} + ${data?.withdrawFees?.AirTickets.toFixed(2)} + ${data?.withdrawFees?.abnCommission.toFixed(2)} + ${data?.withdrawFees?.abnRewards.toFixed(2)})`
          : null,
        totalWithdraw: data?.totalWithdraw,
        fixwalletaddress: data?.fixwalletaddress,
        createdAt: new Date(data?.createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        lastLoginned: data?.lastLogged
          ? new Date(data.lastLogged).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
          : null,
        Reward: data?.rewards,
        BlockWithdraw: data?.blockWithdraw,
        AppBlock: data?.status,
      }));
      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "users");

      // Buffer
      let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      // Binary string
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      // Download
      XLSX.writeFile(workBook, "userData.xlsx");

      setLoading1(false);
    } catch (error) {
      toast.error(error.message || "An error occurred");
      setLoading1(false);
    }
  };

  const blockuser = async (data) => {
    setIsBlock(data);

    try {
      const encryptedData = encryptData(
        JSON.stringify({
          email: data.email,
        })
      );
      await axiosInstanceAuth
        .post(`/admin/blockUser`, {
          data: encryptedData,
        })
        .then(async (res) => {
          const responseData = decryptData(res.data.data);
          toast.success(responseData.message);
          await getUser();
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "User Name", accessor: "full_name", align: "left" },
    { Header: "email", accessor: "email", align: "left" },
    { Header: "Referred By", accessor: "referralCode", align: "left" },
    { Header: "Owned Nodes (sn+pn+mn)", accessor: "node", align: "left" },
    { Header: "NFT AirTickets (bc+ec+fc)", accessor: "nftAirTickets", align: "left" },
    { Header: "Abt Air Ticket", accessor: "AbtairTicket", align: "left" },
    { Header: "Affiliate", accessor: "affiliateStatus", align: "left" },
    { Header: "withdraw fees (nodes+tickets+commi+reward)", accessor: "fees", align: "left" },
    { Header: "Fixed Wallet Address", accessor: "fixwallet", align: "left" },
    { Header: "Total Withdraw", accessor: "totalWithdraw", align: "left" },

    { Header: "Created  On", accessor: "create", align: "left" },
    {
      Header: () => (
        <div>
          Last <br /> Login
        </div>
      ),
      accessor: "lastlogin",
      align: "left",
    },
    { Header: "Rewards", accessor: "rewards", align: "left" },
    { Header: "Block Withdraw", accessor: "blockWithdraw", align: "left" },
    { Header: "App Block", accessor: "blockApp", align: "left" },
  ];

  const handleInput = (e) => {
    const searchdata = e.target.value.trim();
    setSearchTerm(searchdata);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newDebounceTimeout = setTimeout(async () => {
      if (searchdata === "") {
        getUser();
      } else {
        try {
          setLoadingTable(dispatch, true);
          // setLoading(true)
          await axiosInstanceAuth
            .get(`/admin/searchUserByWallet`, {
              params: {
                walletAddress: searchdata,
                page: currentPage,
              },
            })
            .then((res) => {
              let responseData = decryptData(res?.data?.data);

              // setsearchData(responseData);
              // setLoading(false)
              setLoadingTable(dispatch, false);

              const temp = responseData?.data?.map((data, index) => {
                return {
                  id: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {index + 1}
                    </MDTypography>
                  ),
                  full_name: (
                    <div className="userDetails" onClick={() => viewUser(data)}>
                      <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                        {`${data.username} `}
                      </MDTypography>
                    </div>
                  ),
                  email: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data.email}
                    </MDTypography>
                  ),
                  referralCode: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data.referralCode}
                    </MDTypography>
                  ),
                  node: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.totalNodes?.smartNode +
                        data?.totalNodes?.powerNode +
                        data?.totalNodes?.masterNode}
                      ({data?.totalNodes?.smartNode}+{data?.totalNodes?.powerNode}+
                      {data?.totalNodes?.masterNode}) {setNodes((prev) => [...prev, data.nodes])}
                    </MDTypography>
                  ),
                  nftAirTickets: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.totalTicket?.bClass +
                        data?.totalTicket?.eClass +
                        data?.totalTicket?.fClass}
                      ({data?.totalTicket?.bClass}+{data?.totalTicket?.eClass}+
                      {data?.totalTicket?.fClass})
                    </MDTypography>
                  ),
                  AbtairTicket: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data.totalAbtTicket}
                    </MDTypography>
                  ),
                  affiliateStatus: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.affiliate}
                    </MDTypography>
                  ),
                  fees: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {(
                        (data?.withdrawFees?.nodes ?? 0) +
                        (data?.withdrawFees?.AirTickets ?? 0) +
                        (data?.withdrawFees?.abnCommission ?? 0) +
                        data?.withdrawFees?.abnRewards ?? 0
                      ).toFixed(2)}
                      ({(data?.withdrawFees?.nodes).toFixed(2)}+
                      {(data?.withdrawFees?.AirTickets).toFixed(2)}+
                      {(data?.withdrawFees?.abnCommission).toFixed(2)}+
                      {(data?.withdrawFees?.abnRewards).toFixed(2)})
                    </MDTypography>
                  ),
                  fixwallet: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.fixwalletaddress?.slice(0, 4)}....{data?.fixwalletaddress?.slice(-4)}
                      {data?.fixwalletaddress !=
                        "-" && <FaRegCopy className="cursor-pointer" onClick={() => copyToClipboard(data?.fixwalletaddress)} />}
                    </MDTypography>
                  ),
                  totalWithdraw: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data.totalWithdraw}
                    </MDTypography>
                  ),
                  create: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {new Date(data.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </MDTypography>
                  ),
                  lastlogin: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data.lastLogged === "" ? (
                        <div className="desh"> ------------ </div>
                      ) : (
                        <div>
                          {new Date(data.lastLogged).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </div>
                      )}
                    </MDTypography>
                  ),

                  rewards: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data.rewards}
                    </MDTypography>
                  ),
                  blockWithdraw: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        className="TitleColor"
                        onClick={() => blockWithdraw(data)}
                      >
                        {`${data.blockWithdraw}`}
                      </MDButton>
                    </MDTypography>
                  ),

                  blockApp: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        className="TitleColor"
                        onClick={() => blockuser(data)}
                      >
                        {`${data.status}`}
                      </MDButton>
                    </MDTypography>
                  ),
                };
              });
              setuserTableData(temp);
              // setRequestData(temp);
              setPage(1);
              setCurrentPage(1);
            });
        } catch (error) {
          console.error(error);
        }
      }
    }, 500);

    setDebounceTimeout(newDebounceTimeout);
  };

  const onPaginationTable = (event, page) => {
    setCurrentPage(page);
  };

  const viewUserDetails = (data) => {
    localStorage.setItem("profile", JSON.stringify(data));
    navigate("/profile");
  };

  const handlePageChange = (event, newPage) => {
    if (typeof newPage === "number") {
      setCurrentPage(newPage);
    }
  };
  const Token = localStorage.getItem("Token");
  useEffect(() => {
    if (Token) {
      getUser();
    } else {
      navigate("/sign-in");
    }
  }, [currentPage]);

  useEffect(() => {
    affiliateStatusData();
  }, []);

  const setDataTableData = (btnName) => {
    console.log("🚀 ~ setDataTableData ~ btnName:", btnName);
    setSelectedButton(btnName);

    const dataMap = {
      smart: smartAffiliateStatusData,
      power: powerAffiliateStatusData,
      master: masterAffiliateStatusData,
      All: requestData,
    };

    const selectedData = dataMap[btnName];
    setuserTableData(selectedData);
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="coinTitleColor"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <div className="box-user">
                      <MDTypography variant="h6" color="white">
                        User
                      </MDTypography>
                      {loading1 ? (
                        <>
                          <div className="flex justify-center items-center gap-2">
                            <div className="text-yellow-200 font-bold mr-5">
                              It takes a few time due to heavy load.{" "}
                            </div>
                            <div className="dot-spin1"></div>
                            <MDButton
                              // onClick={downloadReport}
                              variant="contained"
                              color="info"
                              sx={{ display: "flex", justifyContent: "center" }}
                              disabled={loading1}
                            >
                              Downloading ..
                            </MDButton>
                          </div>
                        </>
                      ) : (
                        <>
                          <MDButton
                            onClick={() => downloadReport()}
                            variant="contained"
                            color="info"
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <FaDownload className="mr-3" /> Download User
                          </MDButton>
                        </>
                      )}
                    </div>
                  </MDBox>
                  <div className="text-center  mt-5">
                    <ButtonGroup variant="contained" color="primary">
                      <Button
                        onClick={() => setDataTableData("smart")}
                        variant={selectedButton === "smart" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: selectedButton === "smart" ? "blue" : "white",
                          color: selectedButton === "smart" ? "white" : "blue",
                        }}
                      >
                        Smart
                      </Button>
                      <Button
                        onClick={() => setDataTableData("power")}
                        variant={selectedButton === "power" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: selectedButton === "power" ? "blue" : "white",
                          color: selectedButton === "power" ? "white" : "blue",
                        }}
                      >
                        Power
                      </Button>
                      <Button
                        onClick={() => setDataTableData("master")}
                        variant={selectedButton === "master" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: selectedButton === "master" ? "blue" : "white",
                          color: selectedButton === "master" ? "white" : "blue",
                        }}
                      >
                        Master
                      </Button>
                      <Button
                        onClick={() => {
                          setDataTableData("All");
                        }}
                        variant={selectedButton === "All" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: selectedButton === "All" ? "blue" : "white",
                          color: selectedButton === "All" ? "white" : "blue",
                        }}
                      >
                        All
                      </Button>
                    </ButtonGroup>
                  </div>
                  <MDBox pt={3}>
                    {selectedButton === "All" && (
                      <MDBox className="flex justify-between items-center w-full px-5">
                        <Stack padding={2}>
                          <Pagination
                            onChange={handlePageChange}
                            count={page}
                            size="large"
                            defaultPage={1}
                            page={currentPage}
                          />
                        </Stack>
                        <TextField
                          type="text"
                          label="Search here"
                          value={searchTerm}
                          onChange={handleInput}
                        />
                      </MDBox>
                    )}
                    <hr />
                    <DataTable
                      table={{ columns: columns, rows: userTableData }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}
        <Footer />
      </DashboardLayout>
      {/* Edit Faiths Form Pop Up */}
      <Dialog open={openEditPopUp} onClose={handleCloseEditPopUp}>
        <MDBox className="job-status-form-conatiner">
          <DialogContent>
            <DialogContentText>
              <MDBox className="close-btn">
                <MDButton variant="text" color="black">
                  <CloseIcon onClick={handleCloseEditPopUp} />
                </MDButton>
              </MDBox>
              <MDBox className="Heading">
                <MDTypography variant="h3">Edit</MDTypography>
              </MDBox>
              <MDBox component="form" role="form" className="form-content">
                <div>
                  {isEdit.map((item) => (
                    <div>
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="userEmail"
                          label="Email"
                          fullWidth
                          value={item.email}
                          // onChange={userInput}
                          required
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          type="password"
                          name="UserPassword"
                          label="Password"
                          fullWidth
                        // onChange={userInput}
                        />
                      </MDBox>
                    </div>
                  ))}
                </div>
              </MDBox>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="contained"
              color="info"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              Update
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </>
  );
};

export default User;
