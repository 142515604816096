import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Box from "@mui/material/Box";
import NativeSelect from "@mui/material/NativeSelect";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

// import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  FormControlLabel,
} from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
// import "./revoke.css";

function RevokeAirTicketRewards() {
  let navigate = useNavigate();

  const [rewardsName, setRewardsName] = useState("E-Class");
  const [editRewards, setEditRewards] = useState("");
  const [email, setEmail] = useState("");
  const { encryptData, decryptData } = useEncryption();

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleNameRewardsChange = (event) => {
    setRewardsName(event.target.value);
  };

  const ticketChangeHandler = (e) => {
    setEditRewards(e.target.value);
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  // name, price

  const submitRewardsHandler = async (e) => {
    e.preventDefault();

    try {
      if (editRewards > 0) {
        const encryptedData = encryptData(
          JSON.stringify({
            name: rewardsName,
            quantity: editRewards,
            email,
          })
        );
        await axiosInstanceAuth
          .post(`/admin/revokeAirTicket`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);

            if (responseData.status) {
              toast.success(responseData.message);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            toast.error("Someting Wrong!");
          });
      } else {
        toast.warn("enter valid price");
        setEditRewards("");
      }
    } catch (error) { }
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke Air Ticket
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitRewardsHandler}>


                  <MDBox mb={2}>
                    <MenuItem required>
                      <RadioGroup row value={rewardsName} onChange={handleNameRewardsChange}>
                        <FormControlLabel
                          control={<Radio style={{ color: "blue" }} />}
                          value="E-Class"
                          label="E-Class"
                        />
                        <FormControlLabel
                          control={<Radio style={{ color: "blue" }} />}
                          value="B-Class"
                          label="B-Class"
                        />
                        <FormControlLabel
                          control={<Radio style={{ color: "blue" }} />}
                          value="F-Class"
                          label="F-Class"
                        />
                      </RadioGroup>
                    </MenuItem>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      name="UserType"
                      label="Email"
                      value={email}
                      onChange={emailChangeHandler}
                      required
                    />
                  </MDBox>

                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Quantity"
                        value={editRewards}
                        onChange={ticketChangeHandler}
                        required
                      />
                    </MDBox>

                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: "10px", padding: "10px 20px" }}
                      >
                        Revoke Ticket Rewards
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <Footer />
      </DashboardLayout>
    </>
  );
}

export default RevokeAirTicketRewards;
