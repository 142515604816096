import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import NodeDetails from "layouts/nodedetails";
import { CheckBox } from "@mui/icons-material";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';

function RevokeNodes() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState();
  const { encryptData, decryptData } = useEncryption();
  const [selectedValue, setSelectedValue] = useState('smart node');

  const [name, setName] = useState({
    Smart: true,
    Power: false,
    Master: false,
    All: false,
  });
  const [allEmail, setAllEmail] = useState(false);
  const { Smart, Power, Master, All } = name;

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleQuantityChange = (e) => {
    const data = e.target.value
    setQuantity(e.target.value);
  };

  // name, price
  const submitHandler = async(e) => {
    e.preventDefault();
    const trueKeys = Object.keys(name).filter((key) => name[key] === true);
    if (allEmail === true) {
      if (quantity > 0) {
        try {

          await axiosInstanceAuth
            .post(`/admin/revokeNodesFromAllUsers`, {
              name: selectedValue,
              quantity: Number(quantity)
            })
            .then((res) => {
              const responseData = decryptData(res.data.data);
              setQuantity("")
              if (responseData.status === true) {
                toast.success(responseData.message);
              }
              else {
                toast.warn(responseData?.message)
              }
            })
            .catch((res) => {
              let err = decryptData(res.data.data);

              toast.error(err.message);
            });
        } catch (error) {
          console.log("🚀 ~ submitHandler ~ error:", error);
        }
      }
      else {
        toast.warn("please enter valid quantity")
        setQuantity("");
      }
    }
    else {


      try {

        if (quantity > 0) {

         await axiosInstanceAuth
            .post(`/admin/revokeNodes`, {
              email: email,
              quantity:Number(quantity),
              type: selectedValue,
            })
            .then((res) => {
              const responseData = decryptData(res.data.data);
              setEmail("")
              setQuantity("")
              if (responseData.status === true) {
                toast.success(responseData.message);
              }
              else
              {
                toast.warn(responseData.message);
                }
            })
            .catch((res) => {
              let err = decryptData(res.data.data);
              toast.error(err.message);
            });

        }
        else {
          toast.warn("please enter valid quantity")
          setQuantity("")

        }
      } catch (error) {
        console.log("🚀 ~ submitHandler ~ error:", error);
      }
    }
  };


  const handleNameChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleAllEmailChange = () => {
    setAllEmail(!allEmail);
  };
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke Node(s)
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MenuItem required>

                        <RadioGroup row value={selectedValue} onChange={handleNameChange}>
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="smart node"
                            label="Smart"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="power node"
                            label="Power"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="master node"
                            label="Master"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="All"
                            label="All"
                          />
                        </RadioGroup>
                      </MenuItem>
                    </MDBox>
                    <div className="flex gap-5">
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserType"
                          label="Email"
                          value={email}
                          onChange={handleChange}

                          disabled={allEmail}

                        />
                      </MDBox>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={allEmail}
                        onChange={handleAllEmailChange}
                        name="All Email"
                        label="All"
                      />
                    </div>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        {/* View Nodes Start */}
        <NodeDetails />
        {/* View Nodes End */}
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default RevokeNodes;
