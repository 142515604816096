import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

function RevokeAbtairticket() {
  let navigate = useNavigate();
  const [allEmail, sendAllEmail] = useState(false);

  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState("");

  const { encryptData, decryptData } = useEncryption();
const [allEmailcommission, sendAllEmailcommission] = useState(false);
const [quantitycommission, setQuantitycommission] = useState("");

  const [emailcommission, setEmailcommission] = useState("");
  

    const [allEmailRewards, sendAllEmailRewards] = useState(false);
    const [quantityRewards, setQuantityRewards] = useState("");

    const [emailRewards, setEmailRewards] = useState("");
  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };
 const handleChangecommission = (event) => {
   setEmailcommission(event.target.value);
  };
  const handleQuantityChangecommission = (e) => {
    setQuantitycommission(e.target.value);
  };
   const handleQuantityChangeRewards = (e) => {
     setQuantityRewards(e.target.value);
  };
   const handleChangerewards = (event) => {
     setEmailRewards(event.target.value);
   };
  // name, currency, price
  const submitHandler = async (e) => {
    e.preventDefault();

    if (allEmail === true) {
      if (quantity > 0) {
        try {
          await axiosInstanceAuth.post('/admin/revokeAbtAirTicketsallUsers',{
            quantity: Number(quantity)
           }).then((res) => {
               console.log("🚀 ~ submitHandler ~ res:", res)
               const responseData = decryptData(res.data.data);
               console.log("🚀 ~ submitHandler ~ responseData:", responseData)
    
              if(responseData.status)
                {
                    toast.success(responseData.message)
                }
                sendAllEmail("");
                setQuantity("");
                setEmail("")
           })
        } catch (error) {
          console.log("🚀 ~ submitHandler ~ error:", error);
        }
      } else {
        toast.warn("enter valid quantity");
        setQuantity("");
      }
    } else {
      try {
       await axiosInstanceAuth.post('/admin/revokeAbtAirTickets',{
        email:email,
        quantity: Number(quantity)
       }).then((res) => {
           console.log("🚀 ~ submitHandler ~ res:", res)
           const responseData = decryptData(res.data.data);
           console.log("🚀 ~ submitHandler ~ responseData:", responseData)

          if(responseData.status)
            {
                toast.success(responseData.message)
            }
            else
            {
              toast.error(responseData.message)
            }
            sendAllEmail("");
            setQuantity("");
            setEmail("")
       })
      } catch (error) {
        console.log("🚀 ~ submitHandler ~ error:", error);
      }
    }
  };

 const submitHandlercommission = async (e) => {
   e.preventDefault();

   if (allEmailcommission === true) {
     if (quantitycommission > 0) {
       try {
         await axiosInstanceAuth
           .post("/admin/revokeAbCommissionallUsers", {
             quantity: Number(quantitycommission),
           })
           .then((res) => {
             console.log("🚀 ~ submitHandler ~ res:", res);
             const responseData = decryptData(res.data.data);
             console.log("🚀 ~ submitHandler ~ responseData:", responseData);

             if (responseData.status) {
               toast.success(responseData.message);
             }
             sendAllEmailcommission("");
             setQuantitycommission("");
             setEmailcommission("");
           });
       } catch (error) {
         console.log("🚀 ~ submitHandler ~ error:", error);
       }
     } else {
       toast.error("enter valid quantity");
       setQuantity("");
     }
   } else {
     try {
       await axiosInstanceAuth
         .post("/admin/revokeAbnCommission", {
           email: emailcommission,
           quantity: Number(quantitycommission),
         })
         .then((res) => {
           console.log("🚀 ~ submitHandler ~ res:", res);
           const responseData = decryptData(res.data.data);
           console.log("🚀 ~ submitHandler ~ responseData:", responseData);

           if (responseData.status) {
             toast.success(responseData.message);
           }
           sendAllEmailcommission("");
           setQuantitycommission("");
           setEmailcommission("");
         });
     } catch (error) {
       console.log("🚀 ~ submitHandler ~ error:", error);
     }
   }
 };
const submitHandlerRewards = async (e) => {
  e.preventDefault();

  if (allEmailRewards === true) {
    if (quantityRewards > 0) {
      try {
        await axiosInstanceAuth
          .post("/admin/revokeAbnRewardsAllUsers", {
            quantity: Number(quantityRewards),
          })
          .then((res) => {
            console.log("🚀 ~ submitHandler ~ res:", res);
            const responseData = decryptData(res.data.data);
            console.log("🚀 ~ submitHandler ~ responseData:", responseData);

            if (responseData.status) {
              toast.success(responseData.message);
            }
            sendAllEmailRewards("");
            setQuantityRewards("");
            setEmailRewards("");
          });
      } catch (error) {
        console.log("🚀 ~ submitHandler ~ error:", error);
      }
    } else {
      toast.error("enter valid quantity");
      setQuantity("");
    }
  } else {
    try {
      await axiosInstanceAuth
        .post("/admin/revokeAbnRewards", {
          email: emailRewards,
          quantity: Number(quantityRewards),
        })
        .then((res) => {
          console.log("🚀 ~ submitHandler ~ res:", res);
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ submitHandler ~ responseData:", responseData);

          if (responseData.status) {
            toast.success(responseData.message);
          }
          sendAllEmailRewards("");
          setQuantityRewards("");
          setEmailRewards("");
        });
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  }
};
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke Abn Node
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <MDBox component="form" role="form">
                    <div className="flex gap-5">
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserType"
                          label="Email"
                          value={email}
                          onChange={handleChange}
                          disabled={allEmail}
                        />
                      </MDBox>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={allEmail}
                        onChange={() => sendAllEmail(!allEmail)}
                        // onChange={handleNameChange}
                        name="All"
                        label="All"
                        disabled={email}
                      />
                    </div>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: "10px", padding: "10px 20px" }}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        {/* for abn commission revoke */}
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke ABN Commission
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandlercommission}>
                  <MDBox component="form" role="form">
                    <div className="flex gap-5">
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserType"
                          label="Email"
                          value={emailcommission}
                          onChange={handleChangecommission}
                          disabled={allEmailcommission}
                        />
                      </MDBox>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={allEmailcommission}
                        onChange={() => sendAllEmailcommission(!allEmailcommission)}
                        // onChange={handleNameChange}
                        name="All"
                        label="All"
                        disabled={email}
                      />
                    </div>

                    <MDBox mb={2}>
                      <MDInput
                        type="number"
                        name="UserType"
                        label="Quantity"
                        value={quantitycommission}
                        onChange={handleQuantityChangecommission}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: "10px", padding: "10px 20px" }}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        {/* for abn rewards revoke */}
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke ABN Rewards
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandlerRewards}>
                  <MDBox component="form" role="form">
                    <div className="flex gap-5">
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserType"
                          label="Email"
                          value={emailRewards}
                          onChange={handleChangerewards}
                          disabled={allEmailRewards}
                        />
                      </MDBox>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={allEmailRewards}
                        onChange={() => sendAllEmailRewards(!allEmailRewards)}
                        // onChange={handleNameChange}
                        name="All"
                        label="All"
                        disabled={allEmailRewards}
                      />
                    </div>

                    <MDBox mb={2}>
                      <MDInput
                        type="number"
                        name="UserType"
                        label="Quantity"
                        value={quantityRewards}
                        onChange={handleQuantityChangeRewards}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: "10px", padding: "10px 20px" }}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default RevokeAbtairticket;
