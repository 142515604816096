import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, FormControlLabel,Checkbox } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import NodeDetails from "layouts/nodedetails";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

function AddNode() {

  let navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('smart node');
  const [allEmail, sendAllEmail] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");

  const { encryptData, decryptData } = useEncryption();

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

 
  const handleNameChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  // name, currency, price
  const submitHandler = async (e) => {
    e.preventDefault();


    if (allEmail === true) {
      if (quantity > 0) {
        try {
          
         await axiosInstanceAuth
            .post(`/admin/sendNodeToAllUsers`, {
              name: selectedValue,
              quantity:Number(quantity)
            })
            .then((res) => {
              const responseData = decryptData(res.data.data);
  setQuantity("")
              if (responseData.status===true) {
                toast.success(responseData.message);
              }
              else
              {
                toast.warn(responseData?.message)
                }
            })
            .catch((res) => {
              let err = decryptData(res.data.data);
              toast.error(err.message);
            });
        } catch (error) {
          console.log("🚀 ~ submitHandler ~ error:", error);
        }
      }
      else
      {
        toast.warn("enter valid quantity")
        setQuantity("")
        }
    } else {
      try {
       
       await axiosInstanceAuth
          .post(`/admin/sendNode`, {
           name: selectedValue,
           email: email,
           price: Number(price),
           quantity: Number(quantity),
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);

            setQuantity("")
            setEmail("")
            setPrice("")

            if (responseData.status===true) {
              toast.success(responseData.message);

            }
            else
            {
              toast.warn(responseData.message);
              }
          })
          .catch((res) => {
            let err = decryptData(res.data.data);
            toast.error(err.message);
          });
      } catch (error) {
        console.log("🚀 ~ submitHandler ~ error:", error);
      }
    }
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Send Node
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <MDBox component="form" role="form">
                    {/* <InputLabel id="demo-simple-select-required-label">Name</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      value={name}
                      label="Name"
                      autoWidth={true}
                      onChange={handleNameChange}
                      required
                    >
                      <MenuItem value="smart node">Smart Node</MenuItem>
                      <MenuItem value="power node">Power Node</MenuItem>
                      <MenuItem value="master node">Master Node</MenuItem>
                    </Select> */}
                    <MDBox mb={2}>
                      <MenuItem required>
                        <RadioGroup row value={selectedValue} onChange={handleNameChange}>
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="smart node"
                            label="Smart"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="power node"
                            label="Power"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="master node"
                            label="Master"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="All"
                            label="All"
                          />

                        </RadioGroup>
                      </MenuItem>
                    </MDBox>
                    <div className="flex gap-5">

                   
                    <MDBox mb={2}>
                      <MDInput
                        type="email"
                        name="UserType"
                        label="Email"
                        value={email}
                        onChange={handleChange}


                          disabled={allEmail}
                      />
                    </MDBox>
                    <FormControlLabel
                        control={<Checkbox />}
                        checked={allEmail}
                        onChange={() => sendAllEmail(!allEmail)}
                        // onChange={handleNameChange}
                        name="All"
                        label="All"
                        disabled={email}
                    />
                    </div>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Price"
                        value={price}
                        onChange={handlePriceChange}
                        disabled={allEmail}
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>





                  
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        {/* View Nodes Start */}
        <NodeDetails />
        {/* View Nodes End */}
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default AddNode;
