import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import NodeDetails from "layouts/nodedetails";
import { MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import axios from "axios";
import { axiosInstance } from "apiInstances";
import Button from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
function SendReward() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailPerNodes, setEmailPerNodes] = useState("");
  const [quantity, setQuantity] = useState("");
  const [sendAll, setSendAll] = useState("");
  const [selectedValue, setSelectedValue] = useState('ticketRewards');

  const { encryptData, decryptData } = useEncryption();

  // const [name, setName] = useState({
  //   Smart: true,
  //   Power: false,
  //   Master: false,
  //   All: false,
  // });
  const [name, setName] = useState({
    nodeRewards: true,
    ticketRewards: false,
  });

  // const { Smart, Power, Master, All } = name;
  const { nodeRewards, ticketRewards } = name;

  const [allEmail, sendAllEmail] = useState(false);
  const [allEmailPerNodes, sendAllEmailPerNodes] = useState(false);


  // const handleNameChange = (event) => {
  //   setName({
  //     ...name,
  //     [event.target.name]: event.target.checked,
  //   });
  // };
  const handleNameChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChangePerNodes = (event) => {
    setEmailPerNodes(event.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const senAllChangeHandler = (e) => {
    setSendAll(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const trueKeys = Object.keys(name).filter((key) => name[key] === true);
    try {
      
      if (allEmail === true) {
        if (quantity > 0) {


         await axiosInstanceAuth
            .post(`admin/sendrewardsDirectlyToAllUsers`, {
              // data: encryptedData,

              rewards: Number(quantity),
              type: selectedValue,
            })
            .then((res) => {
              const responseData = decryptData(res.data.data);

              setEmail("");
              setQuantity("");
              if (responseData.status) {
                toast.success(responseData.message);
              }
              if (!responseData.status) {
                toast.error(responseData.message);
              }
            })
            .catch((res) => {
              let err = decryptData(res);
              toast.error("Someting Wrong!");
            });
        }
        else {
          toast.warn("please enter valid quantity")
          setQuantity("");
        }
      } else {
        if (quantity > 0)
        {
          
         
        axiosInstanceAuth
          .post(`admin/sendrewardsDirectly`, {
            // data: encryptedData,
            email: email,
            rewards: Number(quantity),
            type: selectedValue,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            setEmail("");
            setQuantity("");
            if (responseData.status) {
             
              toast.success(responseData.message);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            toast.error("Someting Wrong!");
          });
        }
        else {
          toast.warn("please enter valid quantity")
          setQuantity("");
        }
      }
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  };

  const submitAllHandler = (e) => {
    e.preventDefault();

    if (!allEmailPerNodes)
    {
      try {
        // const encryptedData = encryptData(
        //   JSON.stringify({
        //     rewards: Number(quantity),
        //   })
        // );
        axiosInstanceAuth
          .post(`/admin/sendRewards`, {
           
            email: emailPerNodes
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
  
            if (responseData.status) {
              toast.success(responseData.message);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            toast.error("Someting Wrong!");
          });
      } catch (error) {
        console.log("🚀 ~ submitAllHandler ~ error:", error);
      }
    }
    else {
      
    
    try {
      // const encryptedData = encryptData(
      //   JSON.stringify({
      //     rewards: Number(quantity),
      //   })
      // );
      axiosInstanceAuth
        .post(`/admin/sendRewardsAll`, {
          rewards: Number(quantity),
          email: emailPerNodes
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);

          if (responseData.status) {
            toast.success(responseData.message);
          }
          if (!responseData.status) {
            toast.error(responseData.message);
          }
        })
        .catch((res) => {
          let err = decryptData(res);
          console.log("🚀 ~ err", err);
          toast.error("Someting Wrong!");
        });
    } catch (error) {
      console.log("🚀 ~ submitAllHandler ~ error:", error);
      }
    }
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Send Reward(s) directly
              </MDTypography>
            </MDBox>
              <div className="ml-5 mt-5 text-sm">
              When you send rewards individually to user, you need to mention category like node, tickets so we can store in database
              </div>
            <MDBox pt={2} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MenuItem >
                        <RadioGroup row value={selectedValue} onChange={handleNameChange}>

                        
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="ticketRewards"
                            label="Ticket"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="nodeRewards"
                            label="Nodes"
                          />
                        </RadioGroup>
                      </MenuItem>
                    </MDBox>
                    <div className="flex gap-5">
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserType"
                          label="Email"
                          value={email}
                          onChange={handleChange}

                          disabled={allEmail}
                        />
                      </MDBox>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={allEmail}
                        onChange={() => sendAllEmail(!allEmail)}
                        // onChange={handleNameChange}
                        name="All"
                        label="All"
                        disabled={email}
                      />
                    </div>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards"
                        value={quantity}
                        onChange={handleQuantityChange}
                        required
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      {/* <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        // onClick={submitHandler}
                      >
                        Submit
                      </MDButton> */}
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Send Reward(s) To All
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitAllHandler}>
                  <MDBox component="form" role="form">
                    <div className="flex gap-5">
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserType"
                          label="Email"
                          value={emailPerNodes}
                          onChange={handleChangePerNodes}

                          disabled={allEmailPerNodes}
                        />
                      </MDBox>
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={allEmailPerNodes}
                        onChange={() => sendAllEmailPerNodes(!allEmailPerNodes)}
                        // onChange={handleNameChange}
                        name="allEmailPerNodes"
                        label="All"
                        disabled={emailPerNodes}
                      />
                    </div>
                    {/* <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards per nodes"
                        value={sendAll}
                        onChange={senAllChangeHandler}
                        required
                      />
                    </MDBox> */}
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}

                      >
                        Send All
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        {/* View Nodes Start */}
        <NodeDetails />
        {/* View Nodes End */}
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default SendReward;
