import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import DataTable from "examples/Tables/DataTable";
import { Pie } from "react-chartjs-2";
import "./totalAll.css";

function TotalAll() {
  let navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const { decryptData } = useEncryption();
  const [loading, setLoading] = useState(true);
  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const [storeData, setStoreData] = useState({
    rewards: "",
    airTickets: "",
    nodes: "",
  });

  const getUser = () => {
    try {
      axiosInstanceAuth
        .get(`/admin/totalData`)
        .then((res) => {
          const responseData = decryptData(res.data.data);
          if (responseData.status) {
            setStoreData({
              rewards: responseData.rewards,
              airTickets: responseData.airTickets,
              nodes: responseData.totalnodes,
            });
            const temp = [];

            temp.push({
              airTickets: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {responseData.airTickets}
                </MDTypography>
              ),
              rewards: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {responseData.rewards}
                </MDTypography>
              ),
              nodes: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {responseData.totalnodes}
                </MDTypography>
              ),
            });

            setRows(temp);
            setLoading(false);
          }
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getUser();
  }, []);

  const columns = [
    { Header: "AirTickets", accessor: "airTickets", align: "left" },
    { Header: "Rewards", accessor: "rewards", align: "left" },
    { Header: "Nodes", accessor: "nodes", align: "left" },
  ];

  const chartData = {
    labels: ["Air Tickets", "Rewards", "Nodes"],
    datasets: [
      {
        data: [storeData.airTickets, storeData.rewards, storeData.nodes],
        // data: [15, 20, 10],

        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
        ],
      },
    ],
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="totalGrid">
              <MDBox pt={6} pb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="coinTitleColor"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Total
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{ columns: columns, rows: rows }}
                          isSorted={true}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>

              <Pie
                data={chartData}
                options={{
                  title: {
                    display: true,
                    text: "Total Nodes, Rewards and Air Tickets",
                    fontSize: 25,
                  },
                  legend: {
                    display: true,
                    position: "top",
                    labels: {
                      fontColor: "#000",
                    },
                  },
                }}
              />
            </div>
          </>
        )}

        <Footer />
      </DashboardLayout>
    </>
  );
}

export default TotalAll;
