import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NodeDetails from "layouts/nodedetails";
import HistoryodNodeDetails from "layouts/historynodedetails"
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
const ViewNodes = () => {
  const [buttonName, setButtonName] = useState("All")
  const { encryptData, decryptData } = useEncryption();

  let navigate = useNavigate();

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);


  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {/* View Nodes Start */}

        <div className="text-center mt-10 ">
          <ButtonGroup variant="contained" color="primary">
            <Button
              onClick={() => setButtonName("All")}
              variant={buttonName === "All" ? "contained" : "outlined"}
              style={{
                backgroundColor: buttonName === "All" ? "blue" : "white",
                color: buttonName === "All" ? "white" : "blue",
                padding: "10px",
                transition: "background-color 0.3s, color 0.3s", // Add transition properties
              }}
            >
              All Nodes
            </Button>
            <Button
              onClick={() => setButtonName("History")}
              variant={buttonName === "History" ? "contained" : "outlined"}
              style={{
                backgroundColor: buttonName === "History" ? "blue" : "white",
                color: buttonName === "History" ? "white" : "blue",
                padding: "10px",
                transition: "background-color 0.3s, color 0.3s", // Add transition properties
              }}
            >
              History Node
             
            </Button>
          </ButtonGroup>
        </div>

        {/* {buttonName && <NodeDetails buttonName={buttonName} />} */}

        {buttonName === "All" ? <NodeDetails /> : <HistoryodNodeDetails />}

        {/* View Nodes End */}
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default ViewNodes;
