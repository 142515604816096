import React, { useState } from "react";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { axiosInstanceAuth } from "apiInstances";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Box, Card, FormControl } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import NodeDetails from "layouts/historynodedetails";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { toast } from "react-toastify";

const ChangeABNPrice = () => {
  const [price, setPrice] = useState(0);

  const { encryptData, decryptData } = useEncryption();
  const userInput = (e) => {
    e.preventDefault();
    setPrice(e.target.value);
  };
  const testHandler = async (e) => {
    e.preventDefault();
    try {
      await axiosInstanceAuth
        .post("/admin/changeAbnPrice", {
          price: Number(price),
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ submitHandler ~ responseData:", responseData);
          if (responseData?.status) {
            toast.success(responseData?.message);
          } else {
            toast.error(responseData?.message);
          }
        });
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  };
  const rewardsData = async (e) => {
    e.preventDefault();
    try {
      await axiosInstanceAuth
        .post("/admin/changeAbnRewardsPrice", {
          Rewardprice: Number(price),
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          console.log("🚀 ~ submitHandler ~ responseData:", responseData);
          if (responseData?.status) {
            toast.success(responseData?.message);
          } else {
            toast.error(responseData?.message);
          }
        });
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  };
  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Change ABN Price
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={testHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput type="text" name="UserType" label="Price" onChange={userInput} />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Change ABN Rewards Price
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={rewardsData}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput type="text" name="UserType" label="Price" onChange={userInput} />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
      </DashboardLayout> 
    </>
  );
};

export default ChangeABNPrice;




