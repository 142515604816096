import { Grid, Stack, Pagination, TextField, FormControlLabel, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstanceAuth } from "apiInstances";
import { ButtonGroup, Button } from "@mui/material";
import MDButton from "components/MDButton";

const NodeDetails = () => {
  let navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [rows2, setRows2] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false)
  const { encryptData, decryptData } = useEncryption();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedButton, setSelectedButton] = useState("node");
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };



  const getHistoryNodeDetails = async () => {
    setLoading(true)
    try {
      await axiosInstanceAuth
        .get('/admin/nodeHistories')
        .then((res) => {
          console.log("🚀 ~ .then ~ res:", res)
          const responseData = decryptData(res?.data?.data);
          console.log("🚀 ~ .then ~ responseData:", responseData)
          if (responseData?.status) {
            setPage(Math.ceil(res?.data?.count / 10));

            const temp = [];
            responseData?.data?.data?.map((data, index) => {
              temp.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                name: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data?.name}`}
                  </MDTypography>
                ),
                email: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data?.email}
                  </MDTypography>
                ),
                quantity: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data.quantity}
                  </MDTypography>
                ),
                createat: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {new Date(data.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </MDTypography>
                ),
                updateat: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {new Date(data.updatedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </MDTypography>
                ),
              });
            });
            setRows(temp);

            const temp1 = [];
            responseData?.data?.formatAbndata?.map((data, index) => {
              temp1.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                username: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data?.username}`}
                  </MDTypography>
                ),
                useremail: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data?.userEmail}
                  </MDTypography>
                ),
                quantity: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data.quantity}
                  </MDTypography>
                ),
                totalPayWec: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {(data.totalPayWec).toFixed(2)}
                  </MDTypography>
                ),
                createat: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {new Date(data.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </MDTypography>
                ),
                updateat: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {new Date(data.updatedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </MDTypography>
                ),
              });
            });
            setRows1(temp1);

            const temp2 = [];
            responseData?.data?.AddbyAdminAbn?.map((data, index) => {
              temp2.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                name: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data?.name}`}
                  </MDTypography>
                ),
                email: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data?.email}
                  </MDTypography>
                ),
                quantity: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data.quantity}
                  </MDTypography>
                ),
                createat: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {new Date(data.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </MDTypography>
                ),
                updateat: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {new Date(data.updatedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </MDTypography>
                ),
              });
            });
            setRows2(temp2);
            setLoading(false);
          }
        })
        .catch((res) => {
          toast.error(res?.data?.message);
        });
    } catch (error) {
    }
  };


  useEffect(() => {
    checkToken();
    try {
      getHistoryNodeDetails()
    } catch (e) {
      console.warn(e)
    }
  }, []);

  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Quantity", accessor: "quantity", align: "left" },
    { Header: "Created Date", accessor: "createat", align: "left" },
    { Header: "Updated Date", accessor: "updateat", align: "left" },
  ];

  const columns1 = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "userName", accessor: "username", align: "left" },
    { Header: "Email", accessor: "useremail", align: "left" },
    { Header: "Quantity", accessor: "quantity", align: "left" },
    { Header: "totalPayWec", accessor: "totalPayWec", align: "left" },
    { Header: "Created Date", accessor: "createat", align: "left" },
    { Header: "Updated Date", accessor: "updateat", align: "left" },
  ];
  const handleInput = async (e) => {
    const searchdata = e.target.value.trim();
    setSearchTerm(searchdata);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newDebounceTimeout = setTimeout(async () => {
      if (searchdata === "") {
        getHistoryNodeDetails()
      } else {
        try {
          await axiosInstanceAuth.get("admin/searchnode", {
            params: {
              search: searchdata,
              type: selectedButton
            },
          }).then((res) => {
            let responseData = decryptData(res?.data?.data);
            console.log("🚀 ~ awaitaxiosInstanceAuth.post ~ res:", responseData)
            const temp = [];
            const temp1 = [];
            if (selectedButton === "node") {
              responseData?.data?.data?.map((data, index) => {
                temp.push({
                  id: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {index + 1}
                    </MDTypography>
                  ),
                  name: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {`${data?.name}`}
                    </MDTypography>
                  ),
                  email: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data?.email}
                    </MDTypography>
                  ),
                  quantity: (
                    <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                      {data.quantity}
                    </MDTypography>
                  ),
                  createat: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {new Date(data.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </MDTypography>
                  ),
                  updateat: (
                    <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                      {new Date(data.updatedAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </MDTypography>
                  ),


                });
              });
              setRows(temp);
            } else {
              responseData?.data?.Formatteddata?.map((data, index) => {
                temp1.push({
                  id: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {index + 1}
                    </MDTypography>
                  ),
                  username: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {`${data?.username}`}
                    </MDTypography>
                  ),
                  useremail: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data?.userEmail}
                    </MDTypography>
                  ),
                  quantity: (
                    <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                      {data.quantity}
                    </MDTypography>
                  ),
                  totalPayWec: (
                    <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                      {(data.totalPayWec).toFixed(2)}
                    </MDTypography>
                  ),
                  createat: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {new Date(data.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </MDTypography>
                  ),
                  updateat: (
                    <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                      {new Date(data.updatedAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </MDTypography>
                  ),
                });
              });
              setRows1(temp1);
            }
          })
        } catch (error) {
          console.error(error);
        }
      }
    }, 500);
    setDebounceTimeout(newDebounceTimeout);
  };

  // const testData = async () => {
  //   const res = await axiosInstanceAuth.get("admin/HistoryAbnAddByAdmin")
  //   const responseData = decryptData(res?.data?.data);
  //   console.log("🚀 ~ testData ~ responseData:", responseData)
  // }

  return (
    <>
      {loading ? (
        <div className="snippet" data-title=".dot-spin">
          <div className="stage">
            <div className="dot-spin"></div>
          </div>
        </div>
      ) : (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="coinTitleColor"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    {selectedButton === "node" ? "History Nodes" : "History Air Bourne Nodes"}
                  </MDTypography>
                </MDBox>
                <div className="text-center mt-5 flex gap-5 justify-center items-center">
                  <ButtonGroup variant="contained" color="primary">
                    <Button
                      onClick={() => {
                        setSelectedButton("node")
                        setIsAdmin(false)
                      }}
                      variant={selectedButton === "node" ? "contained" : "outlined"}
                      style={{
                        backgroundColor: selectedButton === "node" ? "blue" : "white",
                        color: selectedButton === "node" ? "white" : "blue",
                      }}
                    >
                      node
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedButton("Abn")
                        setIsAdmin(false)
                      }}
                      variant={selectedButton === "Abn" ? "contained" : "outlined"}
                      style={{
                        backgroundColor: selectedButton === "Abn" ? "blue" : "white",
                        color: selectedButton === "Abn" ? "white" : "blue",
                      }}
                    >
                      Abn
                    </Button>
                  </ButtonGroup>
                  {selectedButton === "Abn" &&
                    <FormControlLabel control={<Checkbox onClick={() => {
                      setIsAdmin(!isAdmin)
                    }} />} label="Admin" />
                  }
                </div>
                <MDBox className="flex justify-end m-5">
                  <TextField
                    type="text"
                    label="Search here"
                    value={searchTerm}
                    onChange={handleInput}
                  />
                </MDBox>
                <MDBox>
                  {selectedButton === "node" ? (
                    <>
                      <DataTable
                        table={{ columns: columns, rows: rows }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </>
                  ) : (isAdmin === true && selectedButton === "Abn") ? (
                    <>
                      <DataTable
                        table={{ columns: columns, rows: rows2 }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </>
                  ) : (
                    <>
                      <DataTable
                        table={{ columns: columns1, rows: rows1 }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </>
                  )}

                  {/* {rows.length > 0 && (
                      <Stack padding={2}>
                        <Pagination onChange={onPaginationTable} count={page} size="large" />
                      </Stack>
                    )} */}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </>
  );
};

export default NodeDetails;





