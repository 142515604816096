import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import "./revoke.css";
import NodeDetails from "layouts/nodedetails";

function RevokeReward() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityAll, setQuantityAll] = useState("");
  const { encryptData, decryptData } = useEncryption();

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleQuantityAllChange = (e) => {
    setQuantityAll(e.target.value);
  };

  // name, price
  const submitHandler = async(e) => {
    e.preventDefault();

    try {
      if (quantity > 0) {
        
        
        const encryptedData = encryptData(
          JSON.stringify({
            email: email,
            rewards: quantity,
          })
        );
      await  axiosInstanceAuth
          .post(`/admin/revokeRewards`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            setQuantity("")
            setEmail("")
            if (responseData.status) {
              toast.success(responseData.message);
            }
          })
          .catch((res) => {
            let err = decryptData(res.data.data);
            toast.error(err.message);
          });
      }
      else {
        toast.warn("enter valid rewards")
      }
    } catch (error) {
      console.log("🚀 ~ submitHandler ~ error:", error);
    }
  };

  const submitRevokeHandler = async(e) => {
    e.preventDefault();

    try {
      const encryptedData = encryptData(
        JSON.stringify({
          rewards: quantityAll,
        })
      );
     await axiosInstanceAuth
        .post(`/admin/revokeRewardsAll`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          setQuantityAll("")
          if (responseData.status) {
            toast.success(responseData.message);
          }
        })
        .catch((res) => {
          let err = decryptData(res.data.data);
          toast.error(err.message);
        });
    } catch (error) {
      console.log("🚀 ~ submitRevokeHandler ~ error:", error);
    }
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke Reward(s)
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        type="email"
                        name="UserType"
                        label="Email"
                        value={email}
                        onChange={handleChange}
                        required
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards"
                        value={quantity}
                        onChange={handleQuantityChange}
                        required
                      />
                      <div className="changeFontSize">
                        Rewards per node will not be considered. It will be sent as it is.
                      </div>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Revoke Reward(s) Of All Users
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitRevokeHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards"
                        value={quantityAll}
                        onChange={handleQuantityAllChange}
                        required
                      />
                      <div className="changeFontSize">
                        Rewards per node will not be considered. It will be sent as it is.
                      </div>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        {/* View Nodes Start */}
        <NodeDetails />
        {/* View Nodes End */}
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default RevokeReward;
