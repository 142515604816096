// import { Grid, Stack, Pagination } from "@mui/material";
// import MDBox from "components/MDBox";
// import React, { useEffect, useState } from "react";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import DataTable from "examples/Tables/DataTable";
// import useEncryption from "EncryptDecrypt/EncryptDecrypt";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import { axiosInstanceAuth } from "apiInstances";

// const NodeDetails = ({ buttonName }) => {
//   console.log("🚀 ~ NodeDetails ~ buttonName:", buttonName)
//   let navigate = useNavigate();
//   const [rows, setRows] = useState([]);
//   const { encryptData, decryptData } = useEncryption();
//   const [loading, setLoading] = useState(true);
//   const [page, setPage] = useState(1);

//   const checkToken = () => {
//     const Token = localStorage.getItem("Token");
//     if (!Token) {
//       navigate("/sign-in");
//     }
//   };

//   const getNodeDetails = async () => {
//     setLoading(true)
//     try {
//       const encryptedData = encryptData();
//      await axiosInstanceAuth
//         .get(`/admin/viewNodes`, {
//           data: encryptedData,
//         })
//         .then((res) => {
//           const responseData = decryptData(res.data.data);
//           setLoading(false)
//           if (responseData.status) {
//             setPage(Math.ceil(res.data.count / 10));

//             const temp = [];
//             responseData.data.map((data, index) => {
//               temp.push({
//                 id: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {index + 1}
//                   </MDTypography>
//                 ),
//                 name: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {`${data.name}`}
//                   </MDTypography>
//                 ),
//                 supplies: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {data.supplies}
//                   </MDTypography>
//                 ),
//                 quantity: (
//                   <MDTypography component="a" variant="button " color="text" fontWeight="medium">
//                     {data.quantity}
//                   </MDTypography>
//                 ),
//                 dummyQuantity: (
//                   <MDTypography component="a" variant="button " color="text" fontWeight="medium">
//                     {data.dummyQuantity}
//                   </MDTypography>
//                 ),
//                 supplied_date: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {data.supplied_date}
//                   </MDTypography>
//                 ),
//                 finance_details: data.finance_details.map((i) => (
//                   <div key={i._id}>
//                     <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                       Price {i.price}
//                     </MDTypography>
//                     <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                       Currency {i.currency}
//                     </MDTypography>
//                   </div>
//                 )),


//               });
//             });
//             setLoading(false)

//             setRows(temp);
//           }
//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {
//       console.log("🚀 ~ getUser ~ error:", error);
//     }
//   };




//   const getHistoryNodeDetails = async() => {
//     setLoading(true)
//     try {
//       await axiosInstanceAuth
//         .get(`/admin/nodeHistories`)
//         .then((res) => {
//           const responseData = decryptData(res.data.data);

//           if (responseData.status) {
//             setPage(Math.ceil(res.data.count / 10));

//             const temp = [];
//             responseData?.data?.data?.map((data, index) => {
//               temp.push({
//                 id: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {index + 1}
//                   </MDTypography>
//                 ),
//                 name: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {`${data?.name}`}
//                   </MDTypography>
//                 ),
//                 email: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {data?.email}
//                   </MDTypography>
//                 ),
//                 quantity: (
//                   <MDTypography component="a" variant="button " color="text" fontWeight="medium">
//                     {data.quantity}
//                   </MDTypography>
//                 ),
//                 createat: (
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {new Date(data.createdAt).toLocaleDateString("en-US", {
//                       year: "numeric",
//                       month: "short",
//                       day: "numeric",
//                     })}
//                   </MDTypography>
//                 ),
//                 updateat: (
//                   <MDTypography component="a" variant="button " color="text" fontWeight="medium">
//                     {new Date(data.updatedAt).toLocaleDateString("en-US", {
//                       year: "numeric",
//                       month: "short",
//                       day: "numeric",
//                     })}
//                   </MDTypography>
//                 ),




//               });
//             });
//             setRows(temp);
//             setLoading(false);
//           }
//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {
//       console.log("🚀 ~ getUser ~ error:", error);
//     }
//   };


//   useEffect(() => {
//     checkToken();
//     try {

//       if (buttonName === "All") {

//         getNodeDetails();
//       }
//       else {
//         getHistoryNodeDetails()
//       }
//     } catch (e) {
//       console.warn(e)
//     }
//   }, [buttonName]);

//   const columns = [
//     { Header: "id", accessor: "id", align: "left" },
//     { Header: "Name", accessor: "name", align: "left" },
//     ...(buttonName === "All" ? [{ Header: "Supplies", accessor: "supplies", align: "left" }] : [{ Header: "Email", accessor: "email", align: "left" }]),
//     { Header: "Quantity", accessor: "quantity", align: "left" },
//     ...(buttonName === "All" ? [{ Header: "DummyQuantity", accessor: "dummyQuantity", align: "left" }] : [{ Header: "Created Date", accessor: "createat", align: "left" }]),
//     ...(buttonName === "All" ? [{ Header: "Supply Date", accessor: "supplied_date", align: "left" }] : [{ Header: "Updated Date", accessor: "updateat", align: "left" }]),
//     ...(buttonName === "All" ? [{ Header: "Finance Details", accessor: "finance_details", align: "left" }] : []),
//   ];

//   const onPaginationTable = () => {
//     getNodeDetails();
//   };

//   return (
//     <>
//       {loading ? (
//         <div className="snippet" data-title=".dot-spin">
//           <div className="stage">
//             <div className="dot-spin"></div>
//           </div>
//         </div>
//       ) : (
//         (buttonName === "All" ? (
//           <MDBox pt={6} pb={3}>
//             <Grid container spacing={3}>
//               <Grid item xs={12}>
//                 <Card>
//                   <MDBox
//                     mx={2}
//                     mt={-3}
//                     py={3}
//                     px={2}
//                     variant="gradient"
//                     bgColor="coinTitleColor"
//                     borderRadius="lg"
//                     coloredShadow="info"
//                   >
//                     <MDTypography variant="h6" color="white">
//                       All Nodes
//                     </MDTypography>
//                   </MDBox>
//                   <MDBox pt={3}>
//                     <DataTable
//                       table={{ columns: columns, rows: rows }}
//                       isSorted={true}
//                       entriesPerPage={false}
//                       showTotalEntries={false}
//                       noEndBorder
//                     />
//                     {/* {rows.length > 0 && (
//                       <Stack padding={2}>
//                         <Pagination onChange={onPaginationTable} count={page} size="large" />
//                       </Stack>
//                     )} */}
//                   </MDBox>
//                 </Card>
//               </Grid>
//             </Grid>
//           </MDBox>
//         ) : (
//           <MDBox pt={6} pb={3}>
//             <Grid container spacing={3}>
//               <Grid item xs={12}>
//                 <Card>
//                   <MDBox
//                     mx={2}
//                     mt={-3}
//                     py={3}
//                     px={2}
//                     variant="gradient"
//                     bgColor="coinTitleColor"
//                     borderRadius="lg"
//                     coloredShadow="info"
//                   >
//                     <MDTypography variant="h6" color="white">
//                       History Nodes
//                     </MDTypography>
//                   </MDBox>
//                   <MDBox pt={3}>
//                     <DataTable
//                       table={{ columns: columns, rows: rows }}
//                       isSorted={true}
//                       entriesPerPage={false}
//                       showTotalEntries={false}
//                       noEndBorder
//                     />
//                     {/* {rows.length > 0 && (
//                       <Stack padding={2}>
//                         <Pagination onChange={onPaginationTable} count={page} size="large" />
//                       </Stack>
//                     )} */}
//                   </MDBox>
//                 </Card>
//               </Grid>
//             </Grid>
//           </MDBox>
//         ))
//       )}

//     </>
//   );
// };

// export default NodeDetails;








// // {loading ? (
// //   <div className="snippet" data-title=".dot-spin">
// //     <div className="stage">
// //       <div className="dot-spin"></div>
// //     </div>
// //   </div>
// // ) : (
// //   <MDBox pt={6} pb={3}>
// //     <Grid container spacing={3}>
// //       <Grid item xs={12}>
// //         <Card>
// //           <MDBox
// //             mx={2}
// //             mt={-3}
// //             py={3}
// //             px={2}
// //             variant="gradient"
// //             bgColor="coinTitleColor"
// //             borderRadius="lg"
// //             coloredShadow="info"
// //           >
// //             <MDTypography variant="h6" color="white">
// //              All Nodes
// //             </MDTypography>
// //           </MDBox>
// //           <MDBox pt={3}>
// //             <DataTable
// //               table={{ columns: columns, rows: rows }}
// //               isSorted={true}
// //               entriesPerPage={false}
// //               showTotalEntries={false}
// //               noEndBorder
// //             />
// //             {rows.length > 0 && (
// //               <Stack padding={2}>
// //                 <Pagination onChange={onPaginationTable} count={page} size="large" />
// //               </Stack>
// //             )}
// //           </MDBox>
// //         </Card>
// //       </Grid>
// //     </Grid>
// //   </MDBox>
// // )}














import { Grid, Stack, Pagination } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstanceAuth } from "apiInstances";

const NodeDetails = () => {
  let navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const { encryptData, decryptData } = useEncryption();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  const getNodeDetails = async () => {
    setLoading(true)
    try {
      const encryptedData = encryptData();
     await axiosInstanceAuth
        .get(`/admin/viewNodes`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          setLoading(false)
          if (responseData.status) {
            setPage(Math.ceil(res.data.count / 10));

            const temp = [];
            responseData.data.map((data, index) => {
              temp.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                name: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data.name}`}
                  </MDTypography>
                ),
                supplies: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.supplies}
                  </MDTypography>
                ),
                quantity: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data.quantity}
                  </MDTypography>
                ),
                dummyQuantity: (
                  <MDTypography component="a" variant="button " color="text" fontWeight="medium">
                    {data.dummyQuantity}
                  </MDTypography>
                ),
                supplied_date: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.supplied_date}
                  </MDTypography>
                ),
                finance_details: data.finance_details.map((i) => (
                  <div key={i._id}>
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      Price {i.price}
                    </MDTypography>
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      Currency {i.currency}
                    </MDTypography>
                  </div>
                )),


              });
            });
            setLoading(false)

            setRows(temp);
          }
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.log("🚀 ~ getUser ~ error:", error);
    }
  };




 


  useEffect(() => {
    checkToken();
    try {

    
        getNodeDetails();
    
    } catch (e) {
      console.warn(e)
    }
  }, []);

  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Supplies", accessor: "supplies", align: "left" },
    { Header: "Quantity", accessor: "quantity", align: "left" },
    { Header: "DummyQuantity", accessor: "dummyQuantity", align: "left" },
    { Header: "Supply Date", accessor: "supplied_date", align: "left" },
    { Header: "Finance Details", accessor: "finance_details", align: "left" },
  ];

  const onPaginationTable = () => {
    getNodeDetails();
  };

  return (
    <>
      {loading ? (
        <div className="snippet" data-title=".dot-spin">
          <div className="stage">
            <div className="dot-spin"></div>
          </div>
        </div>
      ) : (
       
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="coinTitleColor"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      All Nodes
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: columns, rows: rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {/* {rows.length > 0 && (
                      <Stack padding={2}>
                        <Pagination onChange={onPaginationTable} count={page} size="large" />
                      </Stack>
                    )} */}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
      
      )}

    </>
  );
};

export default NodeDetails;






