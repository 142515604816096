import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Box from "@mui/material/Box";
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select,FormControlLabel} from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import NodeDetails from "layouts/nodedetails";

// const useStyles = makeStyles({
//   root: {
//     height: 40,
//   },
// });

function EditAirTicket() {
  let navigate = useNavigate();
  const [price, setPrice] = useState("");
  const [editRewards, setEditRewards] = useState("");

  const [name, setName] = useState("E-Class");
  console.log("🚀 ~ EditAirTicket ~ name:", name)

  const [rewardsName, setRewardsName] = useState("E-Class");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNameRewardsChange = (event) => {
    setRewardsName(event.target.value);
  };

  const { encryptData, decryptData } = useEncryption();

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const ticketChangeHandler = (e) => {
    setEditRewards(e.target.value);
  };

  // name, price
  const submitHandler = (e) => {
    e.preventDefault();

    try {
      if (price > 0) {
        
        
        const encryptedData = encryptData(
          JSON.stringify({
            name,
            price,
          })
        );
        axiosInstanceAuth
          .post(`/admin/editAirTicketPrice`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            if (responseData.status) {
              toast.success(responseData.message);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            console.log("🚀 ~ err", err);
            toast.error("Someting Wrong!");
          });
      } else {
        toast.warn("enter valid price")
        setPrice("")
      }

    } catch (error) {}
  };

  const submitRewardsHandler = (e) => {
    e.preventDefault();

    try {
      if (editRewards > 0)
      {
        
      
        const encryptedData = encryptData(
          JSON.stringify({
            name: rewardsName,
            rewardsPerTicket: editRewards,
          })
        );
        axiosInstanceAuth
          .post(`/admin/editAirTicketReward`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);

            if (responseData.status) {
              toast.success(responseData.message);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            console.log("🚀 ~ err", err);
            toast.error("Someting Wrong!");
          });
      } else
      {
        toast.warn("enter valid quantity")
        setEditRewards("")
        }
    } catch (error) {}
  };

  // const classes = useStyles();

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Edit Air Ticket Price
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <MDBox component="form" role="form">
                    {/* <InputLabel id="demo-simple-select-required-label">Name</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      // className={classes.root}
                      value={name}
                      label="Name"
                      autoWidth={true}
                      onChange={handleNameChange}
                      required
                    >
                      <MenuItem value="E-Class">E-Class</MenuItem>
                      <MenuItem value="B-Class">B-Class</MenuItem>
                      <MenuItem value="F-Class">F-Class</MenuItem>
                    </Select> */}

                    <MDBox mb={2}>
                      <MenuItem required>
                        <RadioGroup row value={name} onChange={handleNameChange}>
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="E-Class"
                            label="E-Class"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="B-Class"
                            label="B-Class"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="F-Class"
                            label="F-Class"
                          />

                        </RadioGroup>
                      </MenuItem>
                    </MDBox>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Price"
                        value={price}
                        onChange={handlePriceChange}
                        required
                        autocomplete="off"
                      />
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}

                      >
                        Edit Ticket Price
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Edit Air Ticket Rewards
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitRewardsHandler}>
                  {/* <MDBox>
                    <InputLabel id="demo-simple-select-required-label">Name</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      className="selectHeight"
                      value={rewardsName}
                      label="Name *"
                      autoWidth={true}
                      onChange={handleNameRewardsChange}
                      required
                    >
                      <MenuItem value="E-Class">E-Class</MenuItem>
                      <MenuItem value="B-Class">B-Class</MenuItem>
                      <MenuItem value="F-Class">F-Class</MenuItem>
                    </Select>
                  </MDBox> */}
                  <MDBox mb={2}>
                      <MenuItem required>
                        <RadioGroup row value={rewardsName} onChange={handleNameRewardsChange}>
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="E-Class"
                            label="E-Class"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="B-Class"
                            label="B-Class"
                          />
                          <FormControlLabel
                            control={<Radio style={{ color: 'blue' }} />}
                            value="F-Class"
                            label="F-Class"
                          />

                        </RadioGroup>
                      </MenuItem>
                    </MDBox>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        name="UserType"
                        label="Rewards Per Ticket"
                        value={editRewards}
                        onChange={ticketChangeHandler}
                        required
                      />
                    </MDBox>

                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}

                      >
                        Edit Ticket Rewards
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        {/* View Nodes Start */}
        <NodeDetails />
        {/* View Nodes End */}
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default EditAirTicket;
