import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Box from "@mui/material/Box";
import NativeSelect from "@mui/material/NativeSelect";
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  FormControlLabel
} from "@mui/material";
import { axiosInstanceAuth } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { toast } from "react-toastify";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

function SendNFTAirTicket() {
  let navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('E-Class');

  const [email, setEmail] = useState("");
  const [airEmail, setAirEmail] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const { encryptData, decryptData } = useEncryption();
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");

  const [sendTicketRewardsName, setSendTicketRewardsName] = useState("E-Class");
  const [sendTicketRewardsEmail, setSendTicketRewardsEmail] = useState("");
  const [sendTicketRewardsRewards, setSendTicketRewardsRewards] = useState("");

  const [sendTicketRewardsAllName, setSendTicketRewardsAllName] = useState("E-Class");
  const [sendTicketRewardsAllRewards, setSendTicketAllRewardsAllRewards] = useState("");

  const handlesendTicketRewardsAllName = (event) => {
    setSendTicketRewardsAllName(event.target.value);
  };
  const handlesendTicketRewardsAllRewards = (event) => {
    setSendTicketAllRewardsAllRewards(event.target.value);
  };

  // const handleNameChange = (event) => {
  //   setName(event.target.value);
  // };
  const handleNameChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handlePriceChange = (event) => {
    setQuantity(event.target.value);
  };
  const handleAirEmailChange = (event) => {
    setAirEmail(event.target.value);
  };

  const handlesendTicketRewardsNameChange = (event) => {
    setSendTicketRewardsName(event.target.value);
  };

  const handlesendTicketRewardsEmail = (event) => {
    setSendTicketRewardsEmail(event.target.value);
  };

  const handlesendTicketRewardsRewards = (event) => {
    setSendTicketRewardsRewards(event.target.value);
  };

  const checkToken = () => {
    const Token = localStorage.getItem("Token");
    if (!Token) {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  // name, price
  const submitHandler = (e) => {
    e.preventDefault();

    try {
      const encryptedData = encryptData(
        JSON.stringify({
          email: email,
        })
      );
      axiosInstanceAuth
        .post(`/admin/sendTicketReward`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);
          setEmail("")
          if (responseData.status) {
            toast.success(responseData.message);

            setLoading(false);
          }
          if (!responseData.status) {
            toast.error(responseData.message);

            setLoading(false);
          }
        })
        .catch((res) => {
          let err = decryptData(res);
          toast.error("Someting Wrong!");
        });
    } catch (error) { }
  };

  const submitNFTAirTicket = async (e) => {
    e.preventDefault();

    try {
      if (quantity > 0) {
        const encryptedData = encryptData(
          JSON.stringify({
            email: airEmail,
            quantity: Number(quantity),
            name: selectedValue,
          })
        );
        await axiosInstanceAuth
          .post(`/admin/sendAirTicket`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            setAirEmail("")
            setQuantity("")
            if (responseData.status) {
              toast.success(responseData.message);
              setLoading(false);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
              setLoading(false);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            // toast.error("Someting Wrong!");
          });
      }
      else {
        toast.warn("enter valid quantity")
        setQuantity("")
      }
    } catch (error) { }
  };

  const submitAllHandler = async (e) => {
    e.preventDefault();

    try {
      const encryptedData = encryptData(JSON.stringify());
      await axiosInstanceAuth
        .get(`/admin/sendTicketRewardsAll`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);

          if (responseData.status) {
            toast.success(responseData.message);

            setLoading(false);
          }
          if (!responseData.status) {
            toast.error(responseData.message);

            setLoading(false);
          }
        })
        .catch((res) => {
          let err = decryptData(res);
          toast.error("Someting Wrong!");
        });
    } catch (error) { }
  };

  const submitNFTAirTicketWithName = async (e) => {
    e.preventDefault();

    try {
      if (sendTicketRewardsRewards > 0) {


        const encryptedData = encryptData(
          JSON.stringify({
            email: sendTicketRewardsEmail,
            name: sendTicketRewardsName,
            rewards: Number(sendTicketRewardsRewards),
          })
        );
        await axiosInstanceAuth
          .post(`/admin/sendTicketRewardwithName`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            setSendTicketRewardsEmail("")
            setSendTicketRewardsRewards("")
            if (responseData.status) {
              toast.success(responseData.message);
              setLoading(false);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
              setLoading(false);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            // toast.error("Someting Wrong!");
          });
      } else {
        toast.warn("enter valid quantity")
        setSendTicketRewardsRewards("")
      }
    } catch (error) { }
  };

  const submitNFTAirTicketWithAllName = async (e) => {
    e.preventDefault();

    try {
      if (sendTicketRewardsAllRewards > 0) {


        const encryptedData = encryptData(
          JSON.stringify({
            name: sendTicketRewardsAllName,
            rewards: Number(sendTicketRewardsAllRewards),
          })
        );
        await axiosInstanceAuth
          .post(`/admin/sendTicketRewardsAllwithName`, {
            data: encryptedData,
          })
          .then((res) => {
            const responseData = decryptData(res.data.data);
            setSendTicketAllRewardsAllRewards("")
            if (responseData.status) {
              toast.success(responseData.message);
              setLoading(false);
            }
            if (!responseData.status) {
              toast.error(responseData.message);
              setLoading(false);
            }
          })
          .catch((res) => {
            let err = decryptData(res);
            // toast.error("Someting Wrong!");
          });
      } else {
        toast.warn("enter valid quantity")
        setSendTicketAllRewardsAllRewards("")
      }
    } catch (error) { }
  };

  const getUser = async (data) => {
    try {
      const encryptedData = encryptData(
        JSON.stringify({
          page_no: data.page_no,
          search: data.search,
        })
      );
      await axiosInstanceAuth
        .get(`/admin/getAirTickets`, {
          data: encryptedData,
        })
        .then((res) => {
          const responseData = decryptData(res.data.data);

          if (responseData.status) {
            setPage(Math.ceil(res.data.count / 10));

            const temp = [];
            responseData.data.map((data, index) => {
              temp.push({
                id: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {index + 1}
                  </MDTypography>
                ),
                name: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data.name}`}
                  </MDTypography>
                ),
                price: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.price}
                  </MDTypography>
                ),
                quantity: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.quantity}
                  </MDTypography>
                ),
                rewardsPerTicket: (
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {data.rewardsPerTicket}
                  </MDTypography>
                ),
              });
            });
            setRows(temp);
            setLoading(false);
          }
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) { }
  };

  useEffect(() => {
    getUser({ page_no: currentPage, search: "" });
  }, []);

  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Price", accessor: "price", align: "left" },
    { Header: "Quantity", accessor: "quantity", align: "left" },
    { Header: "Rewards Per Ticket", accessor: "rewardsPerTicket", align: "left" },
    // { Header: "Supply Date", accessor: "supplied_date", align: "left" },
    // { Header: "Finance Details", accessor: "finance_details", align: "left" },
  ];

  const onPaginationTable = (event, page) => {
    setCurrentPage(page);
    getUser({ page_no: page, search: "" });
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="coinTitleColor"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      NFT Air Tickets
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: columns, rows: rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {rows.length > 0 && (
                      <Stack padding={2}>
                        <Pagination onChange={onPaginationTable} count={page} size="large" />
                      </Stack>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        )}

        <div className="grid">
          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
                className="TitleColor heading-width"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Send NFT Air Ticket
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <Box>
                  <FormControl onSubmit={submitNFTAirTicket}>
                    <MDBox component="form" role="form">
                      {/* <InputLabel id="demo-simple-select-required-label">Name</InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        // className={classes.root}
                        value={name}
                        label="Name"
                        autoWidth={true}
                        onChange={handleNameChange}
                        required
                      >
                        <MenuItem value="E-Class">E-Class</MenuItem>
                        <MenuItem value="B-Class">B-Class</MenuItem>
                        <MenuItem value="F-Class">F-Class</MenuItem>
                      </Select> */}
                      <MDBox mb={2}>
                        <MenuItem required>
                          <RadioGroup row value={selectedValue} onChange={handleNameChange}>
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="E-Class"
                              label="E-Class"
                            />
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="B-Class"
                              label="B-Class"
                            />
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="F-Class"
                              label="F-Class"
                            />

                          </RadioGroup>
                        </MenuItem>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          name="UserType"
                          label="Quantity"
                          value={quantity}
                          onChange={handlePriceChange}
                          required
                          autocomplete="off"
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserEmail"
                          label="Email"
                          value={airEmail}
                          onChange={handleAirEmailChange}
                          required
                          autocomplete="off"
                        />
                      </MDBox>

                      <MDBox mt={4} mb={1}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          className="TitleColor"
                          style={{ borderRadius: '10px', padding: '10px 20px' }}

                        >
                          submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </FormControl>
                </Box>
              </MDBox>
            </Card>
          </MDBox>

          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
                className="TitleColor heading-width"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Send NFT Air Ticket Reward(s)
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <p style={{ fontSize: "12px", color: "red" }}>
                  The user will receive default rewards per NFT air ticket.
                </p>
                <p style={{ fontSize: "12px", color: "red" }}>
                  For instance ~ E-Class : 500 rewards per one NFT
                </p>
                <br />
                <Box>
                  <FormControl onSubmit={submitHandler}>
                    <MDBox component="form" role="form">
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserType"
                          label="Email"
                          value={email}
                          onChange={handleChange}
                          required
                        />
                      </MDBox>

                      <MDBox mt={4} mb={1}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          className="TitleColor"
                          style={{ borderRadius: '10px', padding: '10px 20px' }}

                        >
                          Submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </FormControl>
                </Box>
              </MDBox>
            </Card>
          </MDBox>

          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
                className="TitleColor heading-width"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Send NFT Air Ticket Reward(s) With Name
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <Box>
                  <p style={{ fontSize: "12px", color: "red" }}>
                    NFT Air Ticket can be sent by name. The user will receive selected reward per
                    NFT air ticket.
                  </p>
                  <br />
                  <FormControl onSubmit={submitNFTAirTicketWithName}>
                    <MDBox component="form" role="form">


                      <MDBox mb={2}>
                        <MenuItem required>
                          <RadioGroup row value={sendTicketRewardsName} onChange={handlesendTicketRewardsNameChange}>
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="E-Class"
                              label="E-Class"
                            />
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="B-Class"
                              label="B-Class"
                            />
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="F-Class"
                              label="F-Class"
                            />

                          </RadioGroup>
                        </MenuItem>
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          name="UserType"
                          label="Rewards"
                          value={sendTicketRewardsRewards}
                          onChange={handlesendTicketRewardsRewards}
                          required
                          autocomplete="off"
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          name="UserEmail"
                          label="Email"
                          value={sendTicketRewardsEmail}
                          onChange={handlesendTicketRewardsEmail}
                          required
                          autocomplete="off"
                        />
                      </MDBox>

                      <MDBox mt={4} mb={1}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          className="TitleColor"
                          style={{ borderRadius: '10px', padding: '10px 20px' }}

                        >
                          submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </FormControl>
                </Box>
              </MDBox>
            </Card>
          </MDBox>

          <MDBox pt={6} pb={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
                className="TitleColor heading-width"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Send NFT Air Ticket Reward(s) All With Name
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <p style={{ fontSize: "12px", color: "red" }}>
                  NFT Air Ticket can be sent by name. All users will receive selected reward per NFT
                  air ticket.
                </p>
                <br />
                <Box>
                  <FormControl onSubmit={submitNFTAirTicketWithAllName}>
                    <MDBox component="form" role="form">

                      <MDBox mb={2}>
                        <MenuItem required>
                          <RadioGroup row value={sendTicketRewardsAllName} onChange={handlesendTicketRewardsAllName}>
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="E-Class"
                              label="E-Class"
                            />
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="B-Class"
                              label="B-Class"
                            />
                            <FormControlLabel
                              control={<Radio style={{ color: 'blue' }} />}
                              value="F-Class"
                              label="F-Class"
                            />

                          </RadioGroup>
                        </MenuItem>
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="number"
                          name="UserType"
                          label="Rewards"
                          value={sendTicketRewardsAllRewards}
                          onChange={handlesendTicketRewardsAllRewards}
                          required
                          autocomplete="off"
                        />
                      </MDBox>
                      <MDBox mt={4} mb={1}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          className="TitleColor"
                          style={{ borderRadius: '10px', padding: '10px 20px' }}

                        >
                          submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </FormControl>
                </Box>
              </MDBox>
            </Card>
          </MDBox>
        </div>

        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Send NFT Air Ticket Reward(s) To All
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitAllHandler}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}></MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                        style={{ borderRadius: '10px', padding: '10px 20px' }}

                      >
                        Send All
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>

        <Footer />
      </DashboardLayout>
    </>
  );
}

export default SendNFTAirTicket;
